import React, { useState, useCallback, useEffect } from 'react';
import {
  Card, CardBody, Form, FormGroup, Input, Label, Row, Col, Button, Nav,
  NavItem, NavLink, TabContent, TabPane, FormFeedback, CardFooter
} from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaCheck, FaTimes, FaInfoCircle, FaEye } from 'react-icons/fa';
import {
  GetVerificationData,
  EmailVerification,
  AlternateEmailVerification, ViewDocuments,
  HeaderApi, DeleteReferenceDetails, GetReferenceDetailsByID,
  GetReferenceDetails, SaveReferenceDetails, UpdateReferenceDetails
} from '../../api';
import { formatDateTime } from '../../utils';
import SweetAlert from 'sweetalert2';

const cellStyle = { width: '25%', verticalAlign: 'middle' };

const VerificationRow = ({
  label,
  title,
  isAvailable,
  isVerified,
  verificationDate,
  onVerify,
  isVerifier,
  isVerifying
}) => (
  <>
    <th style={cellStyle} scope="row" title={title || label}>
      {label}&nbsp;
      <FaInfoCircle style={{ fontSize: '15px', color: '#17a2b8' }} />
    </th>
    <td style={cellStyle}>
      {isAvailable === 'NA' ? (
        <span className="text-danger">NA</span>
      ) : isVerified ? (
        <>
          <span className="text-success">
            <FaCheck /> Verified
          </span>
          <br />
          {verificationDate && (
            <span className="text-success">
              {verificationDate !== '' ? formatDateTime(verificationDate) : '-'}
            </span>
          )}
        </>
      ) : isVerifier ? (
        <button
          className="btn btn-primary btn-sm"
          onClick={onVerify}
          disabled={isVerifying}
        >
          {isVerifying ? 'Verifying...' : 'Click to Verify'}
        </button>
      ) : (
        <span className="text-danger">
          <FaTimes /> Unverified
        </span>
      )}
    </td>
  </>
);

const VerificationStatus = ({ label, title, verifiedStatus, verificationDate, viewDocument, documentId }) => (
  <>
    <th style={cellStyle} scope="row" title={title || label}>
      {label}&nbsp;
      <FaInfoCircle style={{ fontSize: '15px', color: '#17a2b8' }} />
    </th>
    <td style={cellStyle}>
      {verifiedStatus === 1 ? (
        <>
          <span className="text-success">
            <FaCheck /> VERIFIED{documentId && (<button className="btn btn-link btn-sm" onClick={(e) => viewDocument(e, documentId)} ><FaEye style={{ fontSize: '15px', color: '#17a2b8' }} /></button>)}
          </span>
          <br />
          {verificationDate && (
            <span className="text-success">
              {verificationDate !== '' ? formatDateTime(verificationDate) : '-'}
            </span>
          )}
        </>
      ) : (
        <>
          <span className="text-danger">
            <FaTimes /> UNVERIFIED
          </span>
          <br />
          <span className="text-danger">-</span>
        </>
      )}
    </td>
  </>
);

const headerStyle = {
  backgroundColor: '#0074bd',
  fontWeight: 'bold',
  color: "#fff",
  textAlign: 'center'
};

const Verification = ({ lead_id }) => {
  const [verificationData, setVerificationData] = useState({});
  const [emailVerified, setEmailVerified] = useState(false);
  const [alternateEmailVerified, setAlternateEmailVerified] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState('NA');
  const [alternateEmailAvailable, setAlternateEmailAvailable] = useState('NA');
  const [isVerifying, setIsVerifying] = useState(false);
  const customerDetails = useSelector((state) => state.customerDetails);
  const roleIds = useSelector((state) => state.auth.roles);
  const userID = useSelector((state) => state.auth.userId);
  const [activeTab, setActiveTab] = useState('referenceLog');
  const [editMode, setEditMode] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [referenceData, setReferenceData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const relationType = useSelector(state => state.masters.relationTypeMaster);

  const [formData, setFormData] = useState({
    lead_id: lead_id,
    reference_name: '',
    reference_number: '',
    relation_type: '',
    remarks: '',
  });
  console.log("verificationData", verificationData);
  const validateForm = () => {
    const errors = {};
    if (!formData.reference_name) errors.reference_name = "Name is required";
    if (!formData.reference_number) {
      errors.reference_number = "Reference is required";
    } else if (!/^\d{10}$/.test(formData.reference_number)) {
      errors.reference_number = "Mobile must be 10 digits";
    }
    if (!formData.relation_type) errors.relation_type = "Relation type is required";
    if (!formData.remarks) errors.remarks = "Remarks type is required";
    return errors;
  };

  const isVerifier = useCallback(() => {
    const lead = customerDetails?.data?.lead;
    const leadStatusValid =
      lead?.lead_status_id < 10 && lead?.lead_status_id !== 6 && lead?.lead_status_id >= 4;
    const hasRole1or2 = roleIds.includes(1) || roleIds.includes(2);
    const hasRole3or4WithAssignment =
      (roleIds.includes(3) || roleIds.includes(4)) &&
      (lead?.screener_assign_user_id === userID || lead?.credit_assign_user_id === userID);

    return leadStatusValid && (hasRole1or2 || hasRole3or4WithAssignment);
  }, [customerDetails, roleIds, userID]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fetchVerificationData = useCallback(async () => {
    try {
      const { data } = await axios.get(`${GetVerificationData}${lead_id}`, { headers: HeaderApi });
      setVerificationData(data?.data || {});
      setEmailVerified(data?.data?.email_verified_status || false);
      setAlternateEmailVerified(data?.data?.alternate_email_verified_status || false);
    } catch (error) {
      console.error('Error fetching verification data:', error);
      setVerificationData({});
    }
  }, [lead_id]);

  useEffect(() => {
    fetchVerificationData();
    setEmailAvailable(customerDetails?.data?.lead?.email || 'NA');
    setAlternateEmailAvailable(customerDetails?.data?.lead?.alternate_email || 'NA');
    fetchAllReferenceData();
  }, [fetchVerificationData, customerDetails]);

  useEffect(() => {
    setFormData({
      reference_name: '',
      reference_number: '',
      relation_type: '',
      remarks: '',
    });

    setFormErrors({});
  }, [editMode]);

  const handleVerification = async (url) => {
    setIsVerifying(true);
    try {
      const { data } = await axios.post(url, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
      if (data.status) {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        fetchVerificationData();
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    } finally {
      setIsVerifying(false);
    }
  };

  const handleView = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(lead_id, 10)}/5`, {
        headers: HeaderApi,
        responseType: 'blob'
      });
      const contentType = response.headers['content-type'];

      const file = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(file);
      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 300000);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  const fetchAllReferenceData = async () => {
    const apiUrl = `${GetReferenceDetails}${lead_id}`;
    try {
      const response = await axios.get(apiUrl, { headers: HeaderApi });
      setReferenceData(response.data.data);
    } catch (error) {
      setReferenceData([]);
      console.error('Error fetching state list:', error);
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'reference_number') {
      if (!/^\d{0,10}$/.test(value)) {
        return;
      }

      if (value.length === 10 && !/^\d{10}$/.test(value)) {
        setFormErrors(prevErrors => ({
          ...prevErrors,
          [name]: 'Mobile must be 10 digits'
        }));
      } else {
        setFormErrors(prevErrors => ({
          ...prevErrors,
          [name]: ''
        }));
      }
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value.toUpperCase()
    }));

    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleAddReferenceSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const requestData = {
        lead_id: parseInt(lead_id),
        name: formData.reference_name,
        mobile: parseInt(formData.reference_number),
        relation_type: parseInt(formData.relation_type),
        remarks: formData.remarks
      };

      try {
        const result = await SweetAlert.fire({
          title: 'Are you sure?',
          text: 'You want to add this Reference.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'cancel',
          reverseButtons: false
        });

        if (result.value) {
          const response = await axios.post(`${SaveReferenceDetails}`, requestData, { headers: HeaderApi });
          toast.success('Repayment verified successfully');

          setFormData({
            reference_name: '',
            reference_number: '',
            relation_type: '',
            remarks: '',
          });
          fetchAllReferenceData();
          setEditMode(false);
        } else {
          SweetAlert.fire(
            'Action cancelled by ' + localStorage.getItem('name')
          );
          setEditMode(false);
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to delete this Reference. After delete, you will not be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        await axios.delete(`${DeleteReferenceDetails}${id}`, {
          headers: HeaderApi
        });

        fetchAllReferenceData();
        toast.success('Document deleted successfully');
      } catch (error) {
        console.error('Error deleting document:', error);
        toast.error(error?.response?.data?.error || 'Failed to delete document');
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
  };

  const handleEdit = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${GetReferenceDetailsByID}${id}`, {
        headers: HeaderApi,
      });

      const reference = response.data.data;

      setFormData({
        id: reference.ID,
        lead_id: reference.lead_id,
        reference_name: reference.name,
        reference_number: reference.mobile,
        relation_type: reference.relation_type_id,
        remarks: reference.remarks,
      });

      setUpdateMode(true);
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
    }
  };

  const handleUpdateReferenceSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const requestData = {
        id: formData.id,
        lead_id: parseInt(lead_id),
        name: formData.reference_name,
        mobile: parseInt(formData.reference_number),
        relation_type: parseInt(formData.relation_type),
        remarks: formData.remarks
      };

      try {
        const result = await SweetAlert.fire({
          title: 'Are you sure?',
          text: 'You want to update this Reference.',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'cancel',
          reverseButtons: false
        });

        if (result.value) {
          await axios.put(`${UpdateReferenceDetails}${formData.id}`, requestData, { headers: HeaderApi });
          toast.success('Reference updated successfully');

          setFormData({
            reference_name: '',
            reference_number: '',
            relation_type: '',
            remarks: '',
          });

          fetchAllReferenceData();
          setUpdateMode(false);
        } else {
          SweetAlert.fire('Action cancelled by ' + localStorage.getItem('name'));
          setUpdateMode(false);
        }
      } catch (error) {
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
      }
    } else {
      setFormErrors(errors);
    }
  };

  const renderInput = useCallback((key, value) => {
    switch (key) {
      case "remarks":
        return (
          <textarea
            id={key}
            name={key}
            value={formData.remarks}
            className={`form-control ${formErrors['remarks'] ? 'is-invalid' : ''}`}
            onChange={handleInputChange}
            rows="2"
          ></textarea>
        );
      case "relation_type":
        return (
          <Input id="relation_type" name="relation_type" type="select" Value={formData.relation_type} invalid={!!formErrors['relation_type']} onChange={handleInputChange} >
            <option value="">Select Type</option>
            {relationType.map((type, index) => (
              <option key={index} value={type.ID} selected={type.ID === formData.relation_type}>
                {type.name}
              </option>
            ))}
          </Input>
        );
      default:
        return (
          <Input
            id={key}
            name={key}
            value={formData[key] || ''}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
    }
  }, [formData, formErrors, handleInputChange]);

  const renderFormGroups = (
    <Row>
      {Object.entries(formData).map(([key, value], index) => (
        <Col md={6} key={index} style={{ display: (["id", "lead_id"].includes(key) ? "none" : "") }}>
          <FormGroup>
            <Label for={key}>
              {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </Label>
            {renderInput(key, value)}
            {formErrors[key] && <FormFeedback>{formErrors[key]}</FormFeedback>}
          </FormGroup>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <Card>
        <CardBody>
          <table
            className="table table-bordered table-hover table-striped"
            style={{ tableLayout: 'fixed', width: '100%', marginTop: '10px' }}
          >
            <tbody>
              <tr>
                <th style={cellStyle} scope="row">
                  LEAD ID
                </th>
                <td style={cellStyle}>{lead_id}</td>
                <th style={cellStyle} scope="row">
                  LOAN NUMBER
                </th>
                <td style={cellStyle}>{customerDetails?.data?.lead?.loan_no || 'NA'}</td>
              </tr>
              <tr>
                <VerificationStatus
                  label="MOBILE STATUS"
                  verifiedStatus={customerDetails?.data?.lead?.mobile_verified}
                  verificationDate={customerDetails?.data?.lead?.CreatedAt}
                />
                <VerificationStatus
                  label="PAN CARD STATUS"
                  verifiedStatus={verificationData?.pancard_verified_status}
                  verificationDate={verificationData?.pancard_verified_on}
                />
              </tr>
              <tr>
                <VerificationRow
                  label="EMAIL VERIFICATION STATUS"
                  isAvailable={emailAvailable}
                  isVerified={emailVerified}
                  verificationDate={verificationData?.email_verified_on}
                  onVerify={() => handleVerification(EmailVerification)}
                  isVerifier={isVerifier()}
                  isVerifying={isVerifying}
                />
                <VerificationRow
                  label="OFFICIAL EMAIL VERIFICATION STATUS"
                  isAvailable={alternateEmailAvailable}
                  isVerified={alternateEmailVerified}
                  verificationDate={verificationData?.alternate_email_verified_on}
                  onVerify={() => handleVerification(AlternateEmailVerification)}
                  isVerifier={isVerifier()}
                  isVerifying={isVerifying}
                />
              </tr>
              <tr>
                <VerificationStatus
                  label="AADHAR OCR VERIFICATION STATUS"
                  verifiedStatus={verificationData?.aadhaar_ocr_verified_status}
                  verificationDate={verificationData?.aadhaar_ocr_verified_on}
                />
                <VerificationStatus
                  label="PAN OCR VERIFICATION STATUS"
                  verifiedStatus={verificationData?.pancard_verified_status}
                  verificationDate={verificationData?.pancard_verified_on}
                />
              </tr>
              <tr>
                <VerificationStatus
                  label="EKYC STATUS"
                  verifiedStatus={verificationData?.customer_digital_ekyc_flag}
                  verificationDate={verificationData?.customer_digital_ekyc_done_on}
                />
                <VerificationStatus
                  label="ESIGN STATUS"
                  verifiedStatus={verificationData?.customer_digital_esign_flag}
                  verificationDate={verificationData?.customer_digital_esign_done_on}
                  viewDocument={handleView}
                  documentId={lead_id}
                />
              </tr>
              <tr>
                <VerificationStatus
                  label="ACCOUNT VERIFICATION STATUS"
                  verifiedStatus={verificationData?.account_verified_status}
                  verificationDate={verificationData?.account_verified_on}
                />
                <VerificationStatus
                  label="BANKING VERIFICATION STATUS"
                  title="Account Aggregator or Bank Statement"
                  verifiedStatus={verificationData?.banking_verified_status}
                  verificationDate={verificationData?.banking_verified_on}
                />
              </tr>
            </tbody>
          </table>

          <Nav tabs className="mt-4 mb-4 justify-content-center">
            <NavItem style={{ cursor: "pointer" }}>
              <NavLink
                className={activeTab === 'referenceLog' ? 'active' : ''}
                onClick={() => toggleTab('referenceLog')}
              >
                REFERENCE DETAILS
              </NavLink>
            </NavItem>
            &nbsp;&nbsp;&nbsp;
            {/* {["23", "27"].includes("23") && (
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={activeTab === 'statementAnalysis' ? 'active' : ''}
              onClick={() => toggleTab('statementAnalysis')}
            >
              Add Repayment
            </NavLink>
          </NavItem>
        ) || null} */}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="referenceLog">
              {!updateMode && (
                <>
                  {!editMode && (
                    <>
                      <Row className="table-responsive">
                        <table className="table table-striped table-bordered table-hover" style={{ border: "1px solid #dde2eb" }}>
                          <thead>
                            <tr>
                              <th style={headerStyle}><b>#</b></th>
                              <th style={headerStyle}><b>NAME</b></th>
                              <th style={headerStyle}><b>MOBILE</b></th>
                              <th style={headerStyle}><b>RELATION TYPE</b></th>
                              <th style={headerStyle}><b>REMARKS</b></th>
                              <th style={headerStyle}><b>CREATED ON</b></th>
                              <th style={headerStyle}><b>ACTION</b></th>
                            </tr>
                          </thead>
                          <tbody>
                            {referenceData.length > 0 ? referenceData.map((reference, index) => (

                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{reference.name || '-'}</td>
                                <td>{reference.mobile || '-'}</td>
                                <td>{reference?.relation_type?.name}</td>
                                <td>{reference.remarks}</td>
                                <td>{(reference.CreatedAt != "0001-01-01T00:00:00Z" ? formatDateTime(reference.CreatedAt) : "-")}</td>
                                <td>
                                  {((roleIds.includes(2) && customerDetails?.data?.lead?.lead_status_id <= 10) || (customerDetails?.data?.lead?.lead_status_id <= 10 && lead_id == reference.lead_id && customerDetails?.data?.lead?.screener_assign_user_id == userID)) && (
                                    <>
                                      <a onClick={(e) => handleEdit(e, reference.ID)}><i className="fa fa-edit" style={{ padding: "3px", color: "#00b01c", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                                      <a onClick={(e) => handleDelete(e, reference.ID)}><i className="fa fa-trash" style={{ padding: "3px", color: "#f70036", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                                    </>
                                  ) || <span title={'Action Not Allowed'}>{'ANT'}&nbsp;<FaInfoCircle style={{ fontSize: '15px', color: '#17a2b8' }} /></span>}
                                </td>
                              </tr>
                            )) : (
                              <tr>
                                <td colSpan="16" style={{ textAlign: "center", color: "red" }}>Record Not Found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Row>
                      {(customerDetails?.data?.lead?.lead_status_id <= 10 && customerDetails?.data?.lead?.screener_assign_user_id == userID) && (
                        <CardFooter className="text-center">
                          <Button color="btn btn-outline-info" onClick={() => setEditMode(!editMode)}>{editMode ? "CANCEL" : "ADD REFERENCE"}</Button>
                        </CardFooter>
                      )}
                    </>
                  ) || (
                      <Form onSubmit={handleAddReferenceSubmit}>
                        {renderFormGroups}
                        <CardFooter className="text-center">
                          <Button type="submit" color="btn btn-outline-success">ADD REFERENCE</Button>
                          <Button onClick={() => setEditMode(false)} color="btn btn-outline-danger" style={{ marginLeft: 10 }}>CANCEL</Button>
                        </CardFooter>
                      </Form>
                    )}
                </>
              ) || (
                  <Form onSubmit={handleUpdateReferenceSubmit}>
                    {renderFormGroups}
                    <CardFooter className="text-center">
                      <Button type="submit" color="btn btn-outline-success">UPDATE REFERENCE</Button>
                      <Button onClick={() => setUpdateMode(false)} color="btn btn-outline-danger" style={{ marginLeft: 10 }}>CANCEL</Button>
                    </CardFooter>
                  </Form>
                )}

            </TabPane>

            {/* {["23", "27"].includes("23") && (
          <TabPane tabId="statementAnalysis">
            {submitMessage && (
              <Alert color={submitMessage.type} className="mt-3">
                {submitMessage.text}
              </Alert>
            )}
            <Form onSubmit={handleAddReferenceSubmit}>
              {renderFormGroups}
              <Button type="submit" color="btn btn-outline-success">Statement Analysis</Button>
            </Form>
          </TabPane>
        ) || null} */}

          </TabContent>
        </CardBody>
      </Card >
    </>
  );
};

export default Verification;
