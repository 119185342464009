import {
    GetReligionList, GetSalaryModeList, GetIndustryList,
    GetOccupationsList, GetWorkModeList, GetDepartmentList,
    GetDesignationList, GetAccountTypeList, GetRejectionList,
    GetDocumentsMasterList, GetStateList, MenuListApi, LoginApi,
    GetResidenceTypeList, GetEmployerTypeList, GetBankVerificationStatusList,
    GetExportMasterList, GetReportMasterList, GetPaymentModeList, GetRelationTypeMasterList,
    GetBRECategoryList
} from '../api/index';
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { Lock, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, H5, P, H6, UL, LI } from '../AbstractElements';
import { EmailAddress, ForgotPassword, Login, Password, RememberPassword, LoginWithJWT, } from '../Constant';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../Redux/Slices/authSlice';
import { setMenuItems } from '../Redux/Slices/menuSlice';
import {
    setReligionMaster, clearReligionMaster,
    setSalaryModeMaster, clearSalaryModeMaster,
    setIndustryMaster, clearIndustryMaster,
    setOccupationMaster, clearOccupationMaster,
    setWorkModeMaster, clearWorkModeMaster,
    setDepartmentMaster, clearDepartmentMaster,
    setDesignationMaster, clearDesignationMaster,
    setAccountTypeMaster, clearAccountTypeMaster,
    setRejectionReasonsMaster, clearRejectionReasonsMaster,
    setDocsMaster, clearDocsMaster,
    setStateMaster, clearStateMaster,
    setResidenceMaster, clearResidenceMaster,
    setEmployementTypeMaster, clearEmployementTypeMaster,
    setAccountVerificationStatusMaster, clearAccountVerificationStatusMaster,
    setExportMaster, clearExportMaster, setPaymentModeTypesMaster, clearPaymentModeTypesMaster,
    setReportMaster, clearReportMaster, setRelationTypeMaster, clearRelationTypeMaster,
    setBRECategoryMaster, clearBRECategoryMaster
} from '../Redux/Slices/mastersSlice';
import logo from '../assets/logo.png';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const dispatch = useDispatch();

    const [name, setName] = useState("");

    useEffect(() => {
        localStorage.setItem('Name', name);
    }, [name]);

    const initialState = async (token) => {
        try {
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };

            const dos_response = await axios.get(GetDocumentsMasterList, requestOptions);
            if (dos_response.data.status === 1) {
                dispatch(setDocsMaster(dos_response.data.data));
            } else {
                dispatch(clearDocsMaster());
                return false;
            }

            const state_response = await axios.get(GetStateList, requestOptions);
            if (state_response.data.status === 1) {
                dispatch(setStateMaster(state_response.data.data));
            } else {
                dispatch(clearStateMaster());
                return false;
            }

            const payment_mode_type_response = await axios.get(GetPaymentModeList, requestOptions);
            if (payment_mode_type_response.data.status === 1) {
                dispatch(setPaymentModeTypesMaster(payment_mode_type_response.data.data));
            } else {
                dispatch(clearPaymentModeTypesMaster());
                return false;
            }

            const religion_response = await axios.get(GetReligionList, requestOptions);
            if (religion_response.data.status === 1) {
                dispatch(setReligionMaster(religion_response.data.data));
            } else {
                dispatch(clearReligionMaster());
                return false;
            }

            const salary_mode_response = await axios.get(GetSalaryModeList, requestOptions);
            if (salary_mode_response.data.status === 1) {
                dispatch(setSalaryModeMaster(salary_mode_response.data.data));
            } else {
                dispatch(clearSalaryModeMaster());
                return false;
            }

            const industry_response = await axios.get(GetIndustryList, requestOptions);
            if (industry_response.data.status === 1) {
                dispatch(setIndustryMaster(industry_response.data.data));
            } else {
                dispatch(clearIndustryMaster());
                return false;
            }

            const occupation_response = await axios.get(GetOccupationsList, requestOptions);
            if (occupation_response.data.status === 1) {
                dispatch(setOccupationMaster(occupation_response.data.data));
            } else {
                dispatch(clearOccupationMaster());
                return false;
            }

            const work_mode_response = await axios.get(GetWorkModeList, requestOptions);
            if (work_mode_response.data.status === 1) {
                dispatch(setWorkModeMaster(work_mode_response.data.data));
            } else {
                dispatch(clearWorkModeMaster());
                return false;
            }

            const department_response = await axios.get(GetDepartmentList, requestOptions);
            if (department_response.data.status === 1) {
                dispatch(setDepartmentMaster(department_response.data.data));
            } else {
                dispatch(clearDepartmentMaster());
                return false;
            }

            const designation_response = await axios.get(GetDesignationList, requestOptions);
            if (designation_response.data.status === 1) {
                dispatch(setDesignationMaster(designation_response.data.data));
            } else {
                dispatch(clearDesignationMaster());
                return false;
            }

            const account_type_response = await axios.get(GetAccountTypeList, requestOptions);
            if (account_type_response.data.status === 1) {
                dispatch(setAccountTypeMaster(account_type_response.data.data));
            } else {
                dispatch(clearAccountTypeMaster());
                return false;
            }

            const rejection_response = await axios.get(GetRejectionList, requestOptions);
            if (rejection_response.data.status === 1) {
                dispatch(setRejectionReasonsMaster(rejection_response.data.data));
            } else {
                dispatch(clearRejectionReasonsMaster());
                return false;
            }

            const residence_response = await axios.get(GetResidenceTypeList, requestOptions);
            if (residence_response.data.status === 1) {
                dispatch(setResidenceMaster(residence_response.data.data));
            } else {
                dispatch(clearResidenceMaster());
                return false;
            }

            const employementType_response = await axios.get(GetEmployerTypeList, requestOptions);
            if (employementType_response.data.status === 1) {
                dispatch(setEmployementTypeMaster(employementType_response.data.data));
            } else {
                dispatch(clearEmployementTypeMaster());
                return false;
            }

            const account_verification_status_response = await axios.get(GetBankVerificationStatusList, requestOptions);
            if (account_verification_status_response.data.status === 1) {
                dispatch(setAccountVerificationStatusMaster(account_verification_status_response.data.data));
            } else {
                dispatch(clearAccountVerificationStatusMaster());
                return false;
            }

            const export_response = await axios.get(GetExportMasterList, requestOptions);
            if (export_response.data.status === 1) {
                dispatch(setExportMaster(export_response.data.data));
            } else {
                dispatch(clearExportMaster());
                return false;
            }

            const report_response = await axios.get(GetReportMasterList, requestOptions);
            if (report_response.data.status === 1) {
                dispatch(setReportMaster(report_response.data.data));
            } else {
                dispatch(clearReportMaster());
                return false;
            }

            const relationType_response = await axios.get(GetRelationTypeMasterList, requestOptions);
            if (relationType_response.data.status === 1) {
                dispatch(setRelationTypeMaster(relationType_response.data.data));
            } else {
                dispatch(clearRelationTypeMaster());
                return false;
            }

            const breCategory_response = await axios.get(GetBRECategoryList, requestOptions);
            if (breCategory_response.data.status === 1) {
                dispatch(setBRECategoryMaster(breCategory_response.data.data));
            } else {
                dispatch(clearBRECategoryMaster());
                return false;
            }

            const response = await axios.get(MenuListApi, requestOptions);
            const menuData = response.data;
            if (menuData.data.status === 1) {
                dispatch(setMenuItems(menuData));
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error with the Axios request:', error);
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            return false;
        }
    };

    const signIn = async (e) => {
        e.preventDefault();
        setLoading(true);
        // setValue(man);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { email, password }
        };

        try {
            const response = await axios(LoginApi, requestOptions);
            const userData = response.data;

            if (userData.status === 1) {
                setName(userData.user_data.name);
                if (userData.IsLoggedIn === true) {
                    dispatch(loginSuccess({ token: userData?.access_token, user: userData?.user_data, roles: userData?.role_ids, userId: userData?.user_data.ID, name: userData?.user_data.name }));
                    await initialState(userData.access_token);
                    localStorage.setItem('authenticated', true);

                    const csrfToken = userData.csrf_token;
                    if (csrfToken) {
                        localStorage.setItem('csrf_token', csrfToken); // Save token for future use
                        console.log('CSRF Token:', csrfToken);
                    } else {
                        console.error('No CSRF token in response');
                    }
                }

                localStorage.setItem('token', userData.access_token);
                localStorage.setItem('name', userData.user_data.name);
                localStorage.setItem('super_admin', userData.super_admin);
                localStorage.setItem('user_data', JSON.stringify(userData.user_data));
                localStorage.setItem('role_ids', JSON.stringify(userData.role_ids));
                localStorage.setItem('user_id', userData.user_data.ID);
                localStorage.setItem('role_labels', JSON.stringify(userData.role_labels));
                setLoading(false);
                window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
                return userData;
            } else {
                setLoading(false);
                setErrors({ error: userData.message });
            }
        } catch (error) {
            console.error('Error with the Axios request:', error);
            setLoading(false);
            setErrors({ error: error.response.data.error });
        }
    };

    return (
        <Fragment>
            <Form className="theme-form login-form" style={{
                width: "100%",
                maxWidth: "350px",
                padding: "10px",
                borderRadius: "8px",
                background: "rgba(255, 255, 255, 0.87)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}>
                {/* <div className="login-header text-center">
                    <H4>{Login}</H4>
                    <H6>Welcome back! Log in to your account.</H6>
                </div> */}
                <div className="login-header text-center" style={{ background: '#fff', borderRadius: '25px' }}>
                    <img src={logo} width="60%" alt="logo" />
                </div>
                <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "red" }}>{errors.error ? errors.error : ""}</h5>
                </div>
                {/* <div className="login-social-title">
                    <H5>Sign in with Email</H5>
                </div> */}
                <FormGroup>
                    {/* <Label>{EmailAddress}</Label> */}
                    <div className="input-group"><span className="input-group-text"><Mail /></span>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} placeholder="Username" />
                    </div>
                </FormGroup>
                <FormGroup>
                    {/* <Label>{Password}</Label> */}
                    <div className="input-group"><span className="input-group-text"><Lock /></span>
                        <Input className="form-control" type={togglePassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" placeholder="Password" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>                    </div>
                </FormGroup>
                <FormGroup className="login-btn">
                    {/* <div className="checkbox">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                    </div> */}
                    <Link to={`${process.env.PUBLIC_URL}/forgot-password`} className="link" style={{ color: '#ff5000', fontWeight: 700 }}>
                        {ForgotPassword}?
                    </Link>
                </FormGroup>
                <FormGroup>
                    <Btn attrBtn={{ color: 'warning', className: 'btn-block', disabled: loading ? loading : loading, onClick: (e) => signIn(e) }} >{loading ? 'LOADING...' : LoginWithJWT}</Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default LoginForm;
