import React, { Suspense, useEffect, useState, Fragment } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../Layout/Loader';
import LayoutRoutes from './LayoutRoutes';
import PrivateRoute from './PrivateRoute';
import Signin from '../Auth/Signin';
import Callback from '../Auth/Callback';
import { authRoutes } from './AuthRoutes';
// import PageNotFound from '../Component/Pages/ErrorPages/pagenot-found';
// import Dashboard from '../Pages/DashBoard/Default/Default';

const Routers = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(localStorage.getItem('isLoggedIn'));
  }, []);

  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={'/'} element={<PrivateRoute authenticated={authenticated} />}>
            {authenticated && (
              <Route element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
            <Route path={`${process.env.PUBLIC_URL}`} element={<Signin />} />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/callback`} element={<Callback />} />
          <Route path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );



};


export default Routers;
