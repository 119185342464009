import React, { Fragment, useEffect, useState } from 'react';
import logo from '../../assets/fav.png';
import { useSelector } from 'react-redux';

const Loader = () => {
  const [show, setShow] = useState(true);
  const brandID = useSelector(state => state.auth.user?.brand_id);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const spinnerStyle = {
    border: "8px solid transparent",
    borderTop: "8px solid #3498db",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 1s linear infinite",
  };

  return (
    <Fragment>
      {/* Conditional rendering based on brandID */}
      {show && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(245, 245, 245, 0.9)', // 90% Transparent Background
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
          }}
        >
          {brandID === 1 ? (
            <div
              style={{
                position: 'relative',
                width: '150px',
                height: '150px',
              }}
            >
              {/* Rotating Segmented Ring */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  border: '8px solid transparent',
                  borderTop: '8px solid rgba(255, 115, 0, 0.9)', // 90% Transparent Orange
                  borderRight: '8px solid rgba(30, 30, 143, 0.9)', // 90% Transparent Blue
                  animation: 'rotate 1.5s linear infinite',
                }}
              ></div>

              {/* Static Logo */}
              <img
                src={logo}
                alt="SalaryKart Logo"
                style={{
                  width: '80%',
                  height: '80%',
                  position: 'absolute',
                  top: '10%',
                  left: '10%',
                }}
              />
            </div>
          ) : (
            <>
              <div style={loaderStyle}>
                <div style={spinnerStyle}></div>
              </div>
            </>
          )}
        </div>
      )}

      {/* Global Styles for Keyframe Animation */}
      <style>
        {`
          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Fragment>
  );
};

export default Loader;
