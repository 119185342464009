import { createSlice } from '@reduxjs/toolkit';

// Initial state for the slice
const initialState = {
    docsMaster: [],
    stateMaster: [],
    religionMaster: [],
    salaryModeMaster: [],
    industryMaster: [],
    occupationMaster: [],
    workModeMaster: [],
    departmentMaster: [],
    designationMaster: [],
    accountTypeMaster: [],
    accountVerificationStatusMaster: [],
    rejectionReasonsMaster: [],
    residenceMaster: [],
    employerTypeMaster: [],
    exportMaster: [],
    reportMaster: [],
    paymentModeTypesMaster: [],
    relationTypeMaster: [],
    breCategoryMaster: [],
};

// Create the slice
const masterSlice = createSlice({
    name: 'masters',
    initialState,
    reducers: {
        // Actions for docsMaster
        setDocsMaster: (state, action) => {
            state.docsMaster = action.payload;
        },
        clearDocsMaster: (state) => {
            state.docsMaster = [];
        },

        // Actions for stateMaster
        setStateMaster: (state, action) => {
            state.stateMaster = action.payload;
        },
        clearStateMaster: (state) => {
            state.stateMaster = [];
        },

        // Actions for religionMaster
        setReligionMaster: (state, action) => {
            state.religionMaster = action.payload;
        },
        clearReligionMaster: (state) => {
            state.religionMaster = [];
        },

        // Actions for salaryModeMaster
        setSalaryModeMaster: (state, action) => {
            state.salaryModeMaster = action.payload;
        },
        clearSalaryModeMaster: (state) => {
            state.salaryModeMaster = [];
        },

        // Actions for industryMaster
        setIndustryMaster: (state, action) => {
            state.industryMaster = action.payload;
        },
        clearIndustryMaster: (state) => {
            state.industryMaster = [];
        },

        // Actions for occupationMaster
        setOccupationMaster: (state, action) => {
            state.occupationMaster = action.payload;
        },
        clearOccupationMaster: (state) => {
            state.occupationMaster = [];
        },

        // Actions for workModeMaster
        setWorkModeMaster: (state, action) => {
            state.workModeMaster = action.payload;
        },
        clearWorkModeMaster: (state) => {
            state.workModeMaster = [];
        },

        // Actions for departmentMaster
        setDepartmentMaster: (state, action) => {
            state.departmentMaster = action.payload;
        },
        clearDepartmentMaster: (state) => {
            state.departmentMaster = [];
        },

        // Actions for designationMaster
        setDesignationMaster: (state, action) => {
            state.designationMaster = action.payload;
        },
        clearDesignationMaster: (state) => {
            state.designationMaster = [];
        },

        // Actions for accountTypeMaster
        setAccountTypeMaster: (state, action) => {
            state.accountTypeMaster = action.payload;
        },
        clearAccountTypeMaster: (state) => {
            state.accountTypeMaster = [];
        },

        // Actions for rejectionReasonsMaster
        setRejectionReasonsMaster: (state, action) => {
            state.rejectionReasonsMaster = action.payload;
        },
        clearRejectionReasonsMaster: (state) => {
            state.rejectionReasonsMaster = [];
        },

        // Actions for residenceMaster
        setResidenceMaster: (state, action) => {
            state.residenceMaster = action.payload;
        },
        clearResidenceMaster: (state) => {
            state.residenceMaster = [];
        },

        // Actions for employmentMaster
        setEmployementTypeMaster: (state, action) => {
            state.employerTypeMaster = action.payload;
        },
        clearEmployementTypeMaster: (state) => {
            state.employerTypeMaster = [];
        },

        // Actions for accountVerificationStatusMaster
        setAccountVerificationStatusMaster: (state, action) => {
            state.accountVerificationStatusMaster = action.payload;
        },
        clearAccountVerificationStatusMaster: (state) => {
            state.accountVerificationStatusMaster = [];
        },

        // Actions for exportMaster
        setExportMaster: (state, action) => {
            state.exportMaster = action.payload;
        },
        clearExportMaster: (state) => {
            state.exportMaster = [];
        },

        // Actions for reportMaster
        setReportMaster: (state, action) => {
            state.reportMaster = action.payload;
        },
        clearReportMaster: (state) => {
            state.reportMaster = [];
        },

        // Actions for relationTypeMaster
        setRelationTypeMaster: (state, action) => {
            state.relationTypeMaster = action.payload;
        },
        clearRelationTypeMaster: (state) => {
            state.relationTypeMaster = [];
        },

        // Actions for breCategoryMaster
        setBRECategoryMaster: (state, action) => {
            state.breCategoryMaster = action.payload;
        },
        clearBRECategoryMaster: (state) => {
            state.breCategoryMaster = [];
        },

        // Actions for paymentModeTypesMaster
        setPaymentModeTypesMaster: (state, action) => {
            state.paymentModeTypesMaster = action.payload;
        },
        clearPaymentModeTypesMaster: (state) => {
            state.paymentModeTypesMaster = [];
        },

    },
});

// Export actions for usage in components
export const {
    setDocsMaster, clearDocsMaster,
    setStateMaster, clearStateMaster,
    setReligionMaster, clearReligionMaster,
    setSalaryModeMaster, clearSalaryModeMaster,
    setIndustryMaster, clearIndustryMaster,
    setOccupationMaster, clearOccupationMaster,
    setWorkModeMaster, clearWorkModeMaster,
    setDepartmentMaster, clearDepartmentMaster,
    setDesignationMaster, clearDesignationMaster,
    setAccountTypeMaster, clearAccountTypeMaster,
    setRejectionReasonsMaster, clearRejectionReasonsMaster,
    setResidenceMaster, clearResidenceMaster,
    setEmployementTypeMaster, clearEmployementTypeMaster,
    setAccountVerificationStatusMaster, clearAccountVerificationStatusMaster,
    setExportMaster, clearExportMaster,
    setReportMaster, clearReportMaster,
    setRelationTypeMaster, clearRelationTypeMaster,
    setPaymentModeTypesMaster, clearPaymentModeTypesMaster,
    setBRECategoryMaster, clearBRECategoryMaster,
} = masterSlice.actions;

// Export selectors to access parts of the state
// export const selectDocsMaster = (state) => state.masters.docsMaster;
// export const selectStateMaster = (state) => state.masters.stateMaster;
// export const selectReligionMaster = (state) => state.masters.religionMaster;
// export const selectSalaryModeMaster = (state) => state.masters.salaryModeMaster;
// export const selectIndustryMaster = (state) => state.masters.industryMaster;
// export const selectOccupationMaster = (state) => state.masters.occupationMaster;
// export const selectWorkModeMaster = (state) => state.masters.workModeMaster;
// export const selectDepartmentMaster = (state) => state.masters.departmentMaster;
// export const selectDesignationMaster = (state) => state.masters.designationMaster;
// export const selectAccountTypeMaster = (state) => state.masters.accountTypeMaster;
// export const selectRejectionReasonsMaster = (state) => state.masters.rejectionReasonsMaster;

// Export the reducer to be used in the store
export default masterSlice.reducer;
