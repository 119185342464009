import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Card, CardBody, Form, FormGroup, Input, Label, Media, Row, Col, CardFooter, Container, Button, FormFeedback } from 'reactstrap';
import axios from 'axios';
import user from '../../assets/images/user/7.jpg';
import { GetPersonalDetails, GetProfilePicture, HeaderApi } from '../../api';
import { formatDate, mask_mobile, mask_email, arraysHaveAnyCommonElements } from '../../utils';
import { toast } from 'react-toastify';
import { fetchPersonalData } from '../Task/Tasks';
import { useDispatch } from 'react-redux';
import { setCustomerDetails } from '../../Redux/Slices/customerDetailsSlice';
import { useSelector } from 'react-redux';

const cellStyle = {
  width: '25%',
  whiteSpace: 'nowrap', // Prevent wrapping
  overflow: 'hidden',  // Hide overflow
  textOverflow: 'ellipsis', // Add ellipsis for overflowing text
};

const Personal = ({ lead_id }) => {

  const [data, setData] = useState({});
  const [leaddata, setLeadData] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [residenceState, setResidenceState] = useState({});
  const [residenceCity, setResidenceCity] = useState({});
  const [enduse, setEnduse] = useState({});
  const [maritalStatus, setMaritalStatus] = useState({});
  const [spouseOccupation, setSpouseOccupation] = useState({});
  const [residenceType, setresidenceType] = useState({});
  const [qualification, setQualification] = useState({});
  const [religion, setReligion] = useState({});
  const [fileURL, setFileURL] = useState('');
  const roleIds = useSelector(state => state.auth.roles);
  const userID = useSelector(state => state.auth.userId);
  const [maskingFlag, setMaskingFlag] = useState(true);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      const responseData = await fetchPersonalData(lead_id);
      setData(responseData.data);
      dispatch(setCustomerDetails(responseData.data));

      setLeadData(responseData.data.lead);
      setState(responseData.data.lead.state);
      setCity(responseData.data.lead.city);
      setReligion(responseData.data.religion);
      setResidenceState(responseData.data.residence_state);
      setResidenceCity(responseData.data.residence_city);
      setEnduse(responseData.data.lead.enduse);
      setMaritalStatus(responseData.data.marital_status);
      setSpouseOccupation(responseData.data.spouse_occupation);
      setQualification(responseData.data.qualification);
      setresidenceType(responseData.data.residence_type);
      if (arraysHaveAnyCommonElements(roleIds, [3, 4]) && [4, 5, 7, 8, 9].includes(responseData.data.lead?.lead_status_id) && (userID === responseData.data.lead?.screener_assign_user_id || userID === responseData.data.lead?.credit_assign_user_id)) {
        setMaskingFlag(false);
      } else if (arraysHaveAnyCommonElements(roleIds, [1, 2, 14, 15, 16])) {
        setMaskingFlag(false);
      }

    } catch (error) {
      console.error('Error fetching or parsing personal_data:', error);
    }
  }, [lead_id]);


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    handleView(lead_id);
  }, [fetchData, lead_id]);

  const handleView = async lead_id => {
    if (fileURL) {
      URL.revokeObjectURL(fileURL);
      setFileURL('');
    }
    try {
      const response = await axios.get(`${GetProfilePicture}${parseInt(lead_id, 10)}`, {
        headers: HeaderApi,
        responseType: 'blob'
      });
      const contentType = response.headers['content-type'];

      const file = new Blob(
        [response.data],
        { type: contentType });

      const fileURL = URL.createObjectURL(file);

      setFileURL(fileURL);
    } catch (error) {
      toast.error('Profile picture not found:', error);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="edit-profile" style={{ marginTop: "10px" }}>
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <Form>
                    <div className="profile-title d-flex align-items-center">
                      <img src={fileURL ? fileURL : user} alt="" className="img-70 rounded-circle mr-3" />
                      <div style={{ marginLeft: '30px' }}>
                        <h3 className="mb-1 f-20 txt-primary">{leaddata.first_name ? leaddata.first_name + " " + leaddata.middle_name + " " + leaddata.surname : "-"}</h3>
                        <p>{localStorage.getItem('customer_designation') ? localStorage.getItem('customer_designation') : "-"}</p>
                      </div>
                    </div>
                    <table className="table table-bordered table-hover table-striped" style={{ tableLayout: 'fixed', width: '100%' }} >
                      <tbody>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>FIRST NAME</th>
                          <td style={cellStyle} title={leaddata.first_name}>{leaddata.first_name ? leaddata.first_name : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>MIDDLE NAME</th>
                          <td style={cellStyle} title={leaddata.middle_name}>{leaddata.middle_name ? leaddata.middle_name : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>LAST NAME</th>
                          <td style={cellStyle} title={leaddata.surname}>{leaddata.surname ? leaddata.surname : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>QUALIFICATION</th>
                          <td style={cellStyle} title={qualification.name}>{qualification.name ? qualification.name : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>GENDER</th>
                          <td style={cellStyle} title={data.gender}>{data.gender === 1 ? "MALE" : data.gender === 2 ? "FEMALE" : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>DOB</th>
                          <td style={cellStyle} title={data.dob}>{data.dob ? formatDate(data.dob) : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>MOBILE</th>
                          <td style={cellStyle} title={leaddata.mobile}>{maskingFlag ? mask_mobile(leaddata.mobile) : leaddata.mobile}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>EMAIL</th>
                          <td style={cellStyle} title={leaddata.email}>{maskingFlag ? mask_email(leaddata.email) : leaddata.email}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>CIF NUMBER</th>
                          <td style={cellStyle} title={leaddata.status}>{leaddata.status ? leaddata.customer_id : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" style={cellStyle}>STATUS</th>
                          <td style={cellStyle} title={leaddata.status}>{leaddata.status ? leaddata.status : "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Card>
                <Form>
                  <CardBody>
                    <Fragment>
                      <Row>
                        <table className="table table-bordered table-hover table-striped" style={{ tableLayout: 'fixed', width: '100%' }}>
                          <tbody>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>LEAD ID</th>
                              <td style={cellStyle} title={leaddata.ID}>{leaddata.ID ? leaddata.ID : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>LOAN NO</th>
                              <td style={cellStyle} title={leaddata.loan_no}>{leaddata.loan_no ? leaddata.loan_no : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>USER TYPE</th>
                              <td style={cellStyle} title={leaddata.user_type}>{leaddata.user_type ? leaddata.user_type : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>PAN CARD</th>
                              <td style={cellStyle} title={leaddata.pancard}>{leaddata.pancard ? leaddata.pancard : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>ALTERNATE MOBILE</th>
                              <td style={cellStyle} title={leaddata.alternate_mobile}>{maskingFlag ? mask_mobile(leaddata?.alternate_mobile) : leaddata?.alternate_mobile}</td>
                              <th className="text-nowrap" style={cellStyle}>OFFICIAL EMAIL</th>
                              <td style={cellStyle} title={leaddata.alternate_email}>{maskingFlag ? mask_email(leaddata.alternate_email) : leaddata.alternate_email}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>RELIGION</th>
                              <td style={cellStyle} title={religion.name}>{religion.name ? religion.name : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>MONTHLY INCOME</th>
                              <td style={cellStyle} title={leaddata.emp_monthly_income}>{leaddata.emp_monthly_income ? leaddata.emp_monthly_income : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>LOAN APPLIED</th>
                              <td style={cellStyle} title={leaddata.applied_amount}>{leaddata.applied_amount ? leaddata.applied_amount : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>PURPOSE</th>
                              <td style={cellStyle} title={enduse?.name}>{enduse?.name || "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>STATE</th>
                              <td style={cellStyle} title={state.name}>{state.name ? state.name : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>CITY</th>
                              <td style={cellStyle} title={city.name}>{city.name ? city.name : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>PINCODE</th>
                              <td style={cellStyle} title={leaddata.pincode}>{leaddata.pincode ? leaddata.pincode : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>SOURCE</th>
                              <td style={cellStyle} title={leaddata.source}>{leaddata.source ? leaddata.source : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>UTM SOURCE</th>
                              <td style={cellStyle} title={leaddata.utm_source}>{leaddata.utm_source ? leaddata.utm_source : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>CAMPAIGN</th>
                              <td style={cellStyle} title={leaddata.utm_campaign}>{leaddata.utm_campaign ? leaddata.utm_campaign : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>MARITAL STATUS</th>
                              <td style={cellStyle} title={maritalStatus.name}>{maritalStatus.name ? maritalStatus.name : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>SPOUSE OCCUPATION</th>
                              <td style={cellStyle} title={spouseOccupation.name}>{spouseOccupation.name ? spouseOccupation.name : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>IP ADDRESS</th>
                              <td style={cellStyle} title={leaddata.ip}>{leaddata.ip ? leaddata.ip : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE LINE 1</th>
                              <td style={cellStyle} title={data.residence_address1}>{data.residence_address1 ? data.residence_address1 : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE LINE 2</th>
                              <td style={cellStyle} title={data.residence_address2}>{data.residence_address2 ? data.residence_address2 : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE LINE 3</th>
                              <td style={cellStyle} title={data.residence_address3}>{data.residence_address3 ? data.residence_address3 : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE LANDMARK</th>
                              <td style={cellStyle} title={data.residence_landmark}>{data.residence_landmark ? data.residence_landmark : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE LINE 3</th>
                              <td style={cellStyle} title={residenceState.name}>{residenceState.name ? residenceState.name : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE LANDMARK</th>
                              <td style={cellStyle} title={residenceCity.name}>{residenceCity.name ? residenceCity.name : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE PINCODE</th>
                              <td style={cellStyle} title={data?.residence_pincode}>{data?.residence_pincode || "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE TYPE</th>
                              <td style={cellStyle} title={residenceType.name}>{residenceType.name ? residenceType.name : "-"}</td>
                              <th className="text-nowrap" style={cellStyle}>RESIDENCE SINCE</th>
                              <td style={cellStyle} title={data.residence_since}>{data.residence_since ? formatDate(data.residence_since) : "-"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Row>
                    </Fragment>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Personal;
