import React, { useState } from 'react';
import {
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Button,
    FormGroup,
    Label,
    Input,
    Form
} from 'reactstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ReportData, HeaderApi } from '../api';

const ReportDataComponent = () => {
    const [formErrors, setFormErrors] = useState({});
    const [htmlContent, setHtmlContent] = useState("");

    // Redux selectors
    const reportMaster = useSelector(state => state.masters.reportMaster);
    const userID = useSelector(state => state.auth.user.ID);

    // Predefined date range dropdown states
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    // All form data in one state
    const [formData, setFormData] = useState({
        report_id: 0,       // Default to 0 -> "Select Report"
        user_id: userID,
        start_date: '',
        end_date: ''
    });

    // 1) Predefined date range options (includes "Custom Range")
    const dateRangeOptions = [
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'today', label: 'Today' },
        { value: 'tomorrow', label: 'Tomorrow' },
        { value: 'last7days', label: 'Last 7 Days' },
        { value: 'thisMonth', label: 'This Month' },
        { value: 'last3months', label: 'Last 3 Months' },
        // { value: 'last6months', label: 'Last 6 Months' },
        { value: 'custom', label: 'Custom Range' },
    ];

    // 2) Add a "Select Report" option at the top of your existing array
    const exportOptions = [
        { value: 0, label: 'Select Report' }, // <-- Default item
        ...reportMaster.map(item => ({
            value: item.ID,
            label: `${item.name} - ${item.ID}`,
        }))
    ];

    // Helper: format Date -> 'YYYY-MM-DD' for <Input type="date"/>
    const formatDateForInput = (date) => {
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
        return adjustedDate.toISOString().split('T')[0];
    };

    // Handle predefined/custom date ranges
    const handleDateRangeChange = (option) => {
        setSelectedDateRange(option);

        if (!option) {
            // If cleared, reset date fields
            setFormData(prevData => ({
                ...prevData,
                start_date: '',
                end_date: ''
            }));
            return;
        }

        if (option.value === 'custom') {
            // For custom, just clear out so user can choose dates
            setFormData(prevData => ({
                ...prevData,
                start_date: '',
                end_date: ''
            }));
        } else {
            // Otherwise, set them automatically
            const today = new Date();
            let start, end;

            switch (option.value) {
                case 'yesterday':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    break;
                case 'today':
                    start = today;
                    end = today;
                    break;
                case 'tomorrow':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    break;
                case 'last7days':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
                    end = today;
                    break;
                case 'thisMonth':
                    start = new Date(today.getFullYear(), today.getMonth(), 1);
                    end = today;
                    break;
                case 'last3months':
                    start = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
                    end = today;
                    break;
                case 'last6months':
                    start = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
                    end = today;
                    break;
                default:
                    start = '';
                    end = '';
            }

            setFormData(prevData => ({
                ...prevData,
                start_date: start ? formatDateForInput(start) : '',
                end_date: end ? formatDateForInput(end) : ''
            }));
        }
    };

    // Convert form data before sending
    const convertFormData = (data) => ({
        ...data,
        report_id: parseInt(data.report_id, 10),
        user_id: parseInt(userID, 10),
    });

    // Validate the form before submission
    const validateForm = () => {
        const errors = {};

        // Must choose a real report (report_id != 0)
        if (!formData.report_id) {
            errors.report_id = "Please select a report.";
        }

        // Ensure dates are filled
        if (!formData.start_date) {
            errors.start_date = "Start date is required.";
        }
        if (!formData.end_date) {
            errors.end_date = "End date is required.";
        }

        // Ensure end date isn't earlier than start date
        if (formData.start_date && formData.end_date) {
            const startDate = new Date(formData.start_date);
            const endDate = new Date(formData.end_date);
            if (endDate < startDate) {
                errors.end_date = "End date cannot be earlier than the start date.";
            }
        }

        return errors;
    };

    // Submit the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            try {
                const convertedData = convertFormData(formData);
                const response = await axios.post(ReportData, convertedData, {
                    headers: HeaderApi,
                });

                if (response.data.status === 1) {
                    setHtmlContent(response.data.data);
                    toast.success("Report generated successfully!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000
                    });
                }
            } catch (error) {
                console.error("Export error:", error);
                toast.error(error.response?.data?.error || "Error occurred during report.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000
                });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
    };

    // Handle manual changes to text inputs (for custom date)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    // Handle changes to "Select Report"
    const handleReportChange = (selectedOption) => {
        setFormData(prevData => ({
            ...prevData,
            report_id: selectedOption ? selectedOption.value : 0
        }));
    };

    // Clear all form fields
    const handleClear = () => {
        setFormData({
            report_id: 0,
            user_id: userID,
            start_date: '',
            end_date: ''
        });
        setSelectedDateRange(null);
        setHtmlContent("");
        setFormErrors({});
    };
    window.scrollTo(0, 0);
    return (
        <div className="container my-4">
            <Row className="justify-content-center">
                <Col xl="12">
                    <Card className="shadow border-0">
                        <CardHeader className="bg-primary text-white text-center">
                            <h4 className="mb-0">Generate a Report</h4>
                        </CardHeader>

                        <Form onSubmit={handleSubmit}>
                            <CardBody>
                                {/* Row 1: Report & Date Range selection */}
                                <Row>
                                    {/* Select Report */}
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="exportSelect" className="fw-semibold">
                                                Report Type
                                            </Label>
                                            <Select
                                                className="basic-single"
                                                value={
                                                    exportOptions.find(
                                                        option => option.value === formData.report_id
                                                    )
                                                }
                                                isSearchable={true}
                                                name="report_id"
                                                id="exportSelect"
                                                /* Remove placeholder since "Select Report" is in the list */
                                                options={exportOptions}
                                                onChange={handleReportChange}
                                            />
                                            {formErrors.report_id && (
                                                <div className="text-danger mt-1">{formErrors.report_id}</div>
                                            )}
                                        </FormGroup>
                                    </Col>

                                    {/* Predefined or Custom Date Range */}
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="dateRangeSelect" className="fw-semibold">
                                                Date Range
                                            </Label>
                                            <Select
                                                id="dateRangeSelect"
                                                value={selectedDateRange}
                                                onChange={handleDateRangeChange}
                                                placeholder="Select a Date Range..."
                                                options={dateRangeOptions}
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* Row 2: Start & End Date (only if "Custom Range" selected) */}
                                {selectedDateRange?.value === 'custom' && (
                                    <Row className="mt-3">
                                        {/* Start Date */}
                                        <Col md="6">
                                            <FormGroup>
                                                <Label for="start_date" className="fw-semibold">
                                                    Start Date
                                                </Label>
                                                <Input
                                                    id="start_date"
                                                    type="date"
                                                    name="start_date"
                                                    onChange={handleInputChange}
                                                    value={formData.start_date}
                                                />
                                                {formErrors.start_date && (
                                                    <div className="text-danger mt-1">{formErrors.start_date}</div>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        {/* End Date */}
                                        <Col md="6">
                                            <FormGroup>
                                                <Label for="end_date" className="fw-semibold">
                                                    End Date
                                                </Label>
                                                <Input
                                                    id="end_date"
                                                    type="date"
                                                    name="end_date"
                                                    onChange={handleInputChange}
                                                    value={formData.end_date}
                                                />
                                                {formErrors.end_date && (
                                                    <div className="text-danger mt-1">{formErrors.end_date}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>

                            <CardFooter className="bg-white text-center d-flex justify-content-center">
                                <Button color="primary" className="me-3" type="submit">
                                    <i className="bi bi-file-earmark-arrow-down me-2"></i>
                                    Generate Report
                                </Button>
                                <Button color="secondary" onClick={handleClear}>
                                    <i className="bi bi-x-circle me-2"></i>
                                    Clear
                                </Button>
                            </CardFooter>
                        </Form>

                        {/* If there's HTML content, display it */}
                        {htmlContent && (
                            <CardFooter className="text-center">
                                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                            </CardFooter>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ReportDataComponent;
