import React, { useEffect, useRef } from "react";
import LoginForm from ".";
import imgg from "../assets/images/login/2.png";

const Logins = () => {
    const canvasRef = useRef(null);
    const particles = useRef([]);

    var isLoggedIn = localStorage.getItem('authenticated');
    if (isLoggedIn) {
        localStorage.setItem('authenticated', true);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        window.addEventListener("resize", resizeCanvas);

        const drawParticles = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            particles.current.forEach((particle, index) => {
                // Draw particle
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, 5, 0, Math.PI * 2);
                ctx.fillStyle = "#ff5000";
                ctx.fill();
                ctx.closePath();

                // Draw connections
                for (let i = index + 1; i < particles.current.length; i++) {
                    const other = particles.current[i];
                    const distance = Math.hypot(
                        particle.x - other.x,
                        particle.y - other.y
                    );

                    if (distance < 150) {
                        ctx.beginPath();
                        ctx.moveTo(particle.x, particle.y);
                        ctx.lineTo(other.x, other.y);
                        ctx.strokeStyle = `rgba(255, 80, 0, ${1 - distance / 150})`;
                        ctx.lineWidth = 1;
                        ctx.stroke();
                        ctx.closePath();
                    }
                }
            });
        };

        const updateParticles = () => {
            particles.current = particles.current
                .map((particle) => ({
                    ...particle,
                    x: particle.x + particle.vx,
                    y: particle.y + particle.vy,
                }))
                .filter(
                    (particle) =>
                        particle.x > 0 &&
                        particle.x < canvas.width &&
                        particle.y > 0 &&
                        particle.y < canvas.height
                );
        };

        const animate = () => {
            drawParticles();
            updateParticles();
            requestAnimationFrame(animate);
        };

        animate();

        return () => {
            window.removeEventListener("resize", resizeCanvas);
        };
    }, []);

    const handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        particles.current.push({
            x: clientX,
            y: clientY,
            vx: Math.random() * 2 - 1,
            vy: Math.random() * 2 - 1,
        });

        if (particles.current.length > 200) {
            // Limit particle count for performance
            particles.current.shift();
        }
    };

    return (

        // return (
        //     <Fragment>
        //         <Container fluid={true}>
        //             <Row>
        //                 <Col xl="7" style={{ backgroundImage: `url(${imgg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }} >
        //                 </Col>
        //                 <Col xl="5 p-0">
        //                     <div className="login-card1">
        //                         <LoginForm />
        //                     </div>
        //                 </Col>
        //             </Row>
        //         </Container>
        //     </Fragment >
        // );

        <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundImage: `url(${imgg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
                overflow: "hidden",
            }}
            onMouseMove={handleMouseMove}
        >
            <canvas
                ref={canvasRef}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            ></canvas>
            <div
                style={{
                    position: "relative",
                    top: "30%",
                    zIndex: 2,
                }}
            >
                <LoginForm />
            </div>
        </div>
    );
};

export default Logins;
