import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Col, Row } from 'reactstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { InternalDedupe, HeaderApi } from '../../api';
import { toast } from 'react-toastify';
import { mask_mobile, mask_email, formatDateTime, formatDate } from '../../utils/index';

const Dedupe = ({ lead_id }) => {
  const [dedupeData, setDedupeData] = useState([]);
  const [counts, setCounts] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (lead_id > 0) fetchInternalDedupe(lead_id);
  }, [lead_id]);

  const fetchInternalDedupe = async (leadId) => {
    try {
      const response = await axios.post(
        InternalDedupe,
        { lead_id: parseInt(leadId, 10) },
        { headers: HeaderApi }
      );
      const { list, total, counts } = response.data.data;
      setDedupeData(list);
      setTotal(total);
      setCounts(counts);
    } catch (error) {
      const message = error.response?.data?.error || 'Error occurred';
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const columns = [
    {
      name: 'LEAD ID',
      selector: (row) => row.id,
      sortable: true,
      center: true,
    },
    {
      name: 'LOAN NUMBER',
      selector: (row) => row.loan_no || '-', // Selector for sorting
      sortable: true,
      center: true,
    },
    {
      name: 'CUSTOMER NAME',
      selector: (row) => row.full_name || '-', // Selector for sorting
      sortable: true,
      center: true,
    },
    {
      name: 'PHONE NUMBER',
      selector: (row) => mask_mobile(row.mobile) || '-', // Selector for sorting
      sortable: true,
      center: true,
    },
    {
      name: 'STATUS',
      selector: (row) => row.status || '-', // Selector for sorting
      sortable: true,
      center: true,
    },
  ];

  return (
    <>
      {total > 0 && (
        <Card>
          <Row className="card-block justify-content-center align-items-center">
            <Col xl="10">
              <Table bordered style={styles.table}>
                <tbody>
                  <tr style={styles.rowHeader}>
                    <td style={styles.cellLabel}>TOTAL COUNTS</td>
                    <td style={styles.cellValue}>{total}</td>
                    <td style={styles.cellLabel}>PANCARD COUNTS</td>
                    <td style={styles.cellValue}>{counts.pancard || '-'}</td>
                  </tr>
                  <tr style={styles.rowHeader}>
                    <td style={styles.cellLabel}>MOBILE COUNTS</td>
                    <td style={styles.cellValue}>{counts.mobile || '-'}</td>
                    <td style={styles.cellLabel}>ALTERNATE MOBILE COUNTS</td>
                    <td style={styles.cellValue}>
                      {counts.alternate_mobile || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.cellLabel}>EMAIL VERIFICATION</td>
                    <td style={styles.cellValue}>{counts.email || '-'}</td>
                    <td style={styles.cellLabel}>ALTERNATE EMAIL COUNTS</td>
                    <td style={styles.cellValue}>
                      {counts.alternate_email || '-'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      )}

      <DataTable
        // title="Duplicate Records"
        columns={columns}
        data={dedupeData}
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
        pagination
        highlightOnHover
        pointerOnHover
        striped
        responsive
        onRowClicked={(row) => {
          window.location.href = `${process.env.PUBLIC_URL}/lead-details/${row.id}/${row.lead_status_id}` || '#';
        }}
      />
    </>
  );
};

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },

  rowHeader: {
    backgroundColor: '#f8f9fa',
    borderBottom: '2px solid #dee2e6',
  },

  cellLabel: {
    width: '25%',
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#f1f1f1',
    borderRight: '1px solid #dee2e6',
  },

  cellValue: {
    width: '25%',
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
  },

  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default Dedupe;
