import React, { useState, useEffect, useCallback } from 'react';
import { Col, Card, CardHeader, Row, Button, Badge } from 'reactstrap';
import { FaDownload } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderApi, GetLeadDetailsList, LeadAllocation } from '../../api/index';
import { mask_mobile, mask_email, arraysHaveAnyCommonElements, formatDateTime, formatDate } from '../../utils';

const rowsPerPageOptions = [5, 10, 15, 20];

const LeadList = (props) => {
  const [data, setData] = useState([]);
  const [leadDataCount, setLeadDataCount] = useState(0);
  const [leadDataAmount, setLeadDataAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [allocationOn, setAllocationOn] = useState(false);
  const [startDate, endDate] = dateRange;

  const lead_status_id = parseInt(props.props.lead_status_id, 10);
  const title = props.props.title;
  const label = props.props.label;
  const normalizedLabel = props.props.title?.toLowerCase() || '';
  const roleIds = useSelector((state) => state.auth.roles);
  const [maskingFlag, setMaskingFlag] = useState(true);

  const navigate = useNavigate();

  const fetchLeadData = useCallback(async () => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderApi,
      data: {
        lead_status_id,
        label,
        start: (currentPage - 1) * rowsPerPage,
        limit: rowsPerPage,
        start_date: dateRange[0] ? new Date(dateRange[0]).toLocaleDateString('en-CA') : null,
        end_date: dateRange[1] ? new Date(dateRange[1]).toLocaleDateString('en-CA') : null,
      },
    };

    try {
      const response = await axios(GetLeadDetailsList, requestOptions);
      if (Array.isArray(response.data.data)) {
        setData(response.data.data);
        setLeadDataCount(response.data.total_count);
        setLeadDataAmount(response.data?.total_amount || 0);

        if (
          arraysHaveAnyCommonElements(roleIds, [3, 4]) &&
          [4, 5, 7, 8, 9].includes(lead_status_id)
        ) {
          setMaskingFlag(false);
        } else if (arraysHaveAnyCommonElements(roleIds, [1, 2, 14, 15, 16])) {
          setMaskingFlag(false);
        }
      } else {
        setData([]);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error fetching data', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  }, [currentPage, lead_status_id, rowsPerPage, dateRange, roleIds]);

  useEffect(() => {
    fetchLeadData();
  }, [fetchLeadData]);

  const handleRowSelect = (row) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((selected) => selected.id === row.id);
      if (isSelected) {
        return prevSelected.filter((selected) => selected.id !== row.id);
      } else {
        return [...prevSelected, row];
      }
    });
  };

  const handleSelectAll = useCallback(() => {
    if (data.length === 0) {
      setSelectedRows([]);
      return;
    }
    const newSelected = selectedRows.length === data.length ? [] : [...data];
    setSelectedRows(newSelected);
  }, [data, selectedRows]);


  const allocated = () => {
    let selected = [];
    selectedRows.forEach((row) => {
      selected.push(String(row.id));
    });

    if (selected.length === 0) {
      toast.error("Please select atleast one lead", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000

      });
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: HeaderApi,
      data: { lead_status_id: lead_status_id, lead_list: selected }
    };

    axios(LeadAllocation, requestOptions).then((response) => {
      if (response.data.status === 1) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        fetchLeadData();
        // window.location.reload();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
      }
    }).catch((error) => {
      console.error('Error with the Axios request:', error);
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    });
  };

  const navigateToLeadDetails = (id) => {
    navigate(`${process.env.PUBLIC_URL}/lead-details/${id}/${lead_status_id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if ([3, 6, 15].includes(lead_status_id) && leadDataCount > 0) {
      setAllocationOn(true);
    }
  }, [lead_status_id, leadDataCount]);

  const downloadCSV = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: HeaderApi,
        data: {
          lead_status_id,
          label,
          start_date: dateRange[0] ? new Date(dateRange[0]).toLocaleDateString('en-CA') : null,
          end_date: dateRange[1] ? new Date(dateRange[1]).toLocaleDateString('en-CA') : null,
          limit: 2000, // Fetch all data
        },
      };

      // Fetch complete dataset from API
      const response = await axios(GetLeadDetailsList, requestOptions);

      if (!response.data || !Array.isArray(response.data.data) || response.data.data.length === 0) {
        toast.error('No data available to download', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        return;
      }

      const headers = ['ID', 'Name', 'Email', 'Mobile', 'Loan Amount'];
      const csvRows = [headers.join(',')];

      response.data.data.forEach((row) => {
        const values = [
          row.id,
          row.cust_full_name,
          maskingFlag ? mask_email(row.email) : row.email,
          maskingFlag ? mask_mobile(row.mobile) : row.mobile,
          row.applied_amount,
        ];
        csvRows.push(values.join(','));
      });

      const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `complete_leads_${new Date().toISOString().slice(0, 10)}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Complete dataset downloaded successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Download Error:', error);
      toast.error(
        error.response?.data?.error || 'Failed to download the dataset.',
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        }
      );
    }
  };

  const relevantLabels = ['pre-collection', 'collection-pending', 'recovery-pending', 'pre-closure', 'closure', 'closed', 'disbursed', 'disburse-pending'];
  const columns = [
    ...(allocationOn
      ? [
        {
          name: (
            <>
              {'ALL'}&nbsp;
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedRows.length === data.length && data.length > 0}
                indeterminate={
                  selectedRows.length > 0 && selectedRows.length < data.length
                }
              />
            </>
          ),
          cell: (row) => (
            <input
              type="checkbox"
              checked={selectedRows.some((selected) => selected.id === row.id)}
              onChange={() => handleRowSelect(row)}
            />
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]
      : []),
    { name: '#ID', selector: (row) => row.id, sortable: true },
    { name: 'NAME', selector: (row) => row.cust_full_name, sortable: true },
    { name: 'PAN CARD', selector: (row) => row.pancard, sortable: true },
    {
      name: 'MOBILE',
      selector: (row) => (maskingFlag ? mask_mobile(row.mobile) : row.mobile),
      sortable: true,
    },
    {
      name: 'EMAIL',
      selector: (row) => (maskingFlag ? mask_email(row.email) : row.email),
      sortable: true,
    },
    {
      name: 'LOAN AMOUNT',
      selector: (row) => {
        return relevantLabels.includes(normalizedLabel) ? row.loan_recommended : row.applied_amount;
      },
      sortable: true,
    },
    { name: 'USER TYPE', selector: (row) => row.user_type, sortable: true },
    {
      name: relevantLabels.includes(normalizedLabel) ? 'REPAY DATE' : 'CREATED DATE',
      selector: (row) => {
        return relevantLabels.includes(normalizedLabel) ? formatDate(row.repayment_date) : formatDateTime(row.created_at);
      },
      sortable: true,
      title: (row) => {
        return relevantLabels.includes(normalizedLabel) ? formatDate(row.repayment_date) : formatDateTime(row.created_at);
      },
    },
  ];


  return (
    <Col sm="12">
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Badge
              color="info"
              pill
              className="ml-3"
              style={{ fontSize: '16px', padding: '10px 15px' }}
            >
              {title} : {Number(leadDataCount).toLocaleString('en')} {lead_status_id > 13 ? `| ₹ ${Number(leadDataAmount).toLocaleString('en')}` : ''}
            </Badge>
            {allocationOn && (
              <Button
                color="primary"
                onClick={allocated}
                style={{ marginLeft: '10px', borderRadius: '5px' }}
              >
                Allocate
              </Button>
            )}
          </div>
          <div className="d-flex align-items-center">

            <div className="mr-3" style={{ marginRight: '10px' }}>
              <DatePicker
                selectsRange
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => setDateRange(update)}
                isClearable
                placeholderText="Select Date Range"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                iconPosition="right"
                icon={<i className="fa fa-calendar" style={{ fontSize: '19px', color: '#1d97ff' }} />}
                showIcon
              />
            </div>


            <Button color="btn btn-btn btn-outline-info" onClick={downloadCSV} style={{ padding: '9px 9px 5px 14px' }}>
              <FaDownload style={{ marginRight: '5px' }} />
            </Button>

          </div>
        </CardHeader>
        <hr style={{ marginTop: '-10px' }} />
        <Row>
          <Col sm="12">
            <div className="table-responsive" style={{ marginLeft: 15, marginRight: 15 }}>
              <DataTable
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={leadDataCount}
                paginationRowsPerPageOptions={rowsPerPageOptions}
                paginationPerPage={rowsPerPage}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                highlightOnHover
                responsive
                onRowClicked={(row) => navigateToLeadDetails(row.id)}
                pointerOnHover
                striped
                selectableRowsNoSelectAll
                selectableRowsComponent={allocationOn ? 'checkbox' : null}
                noDataComponent={
                  <div style={{ color: 'red', textAlign: 'center', marginBottom: 20 }}>
                    <strong>Sorry, no data available for the chosen date range or category.</strong>
                  </div>
                }
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default LeadList;
