import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { P } from '../../AbstractElements';
import { useSelector } from 'react-redux';

const Footer = () => {

  const brandName = useSelector(state => state.auth.user?.brand?.name);
  const year = new Date().getFullYear();

  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md='12' className="footer-copyright text-center">
              <P attrPara={{ className: 'mb-0' }}>&copy; {year} <a href="#"><strong>Finclouds Private Limited</strong></a></P>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};
export default Footer;
