import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaInfoCircle, FaCheckCircle, FaTimesCircle, FaQuestionCircle } from "react-icons/fa";
import {
  Button,
  Card, CardBody,
  CardFooter
} from 'reactstrap';
import { useSelector } from 'react-redux';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import axios from 'axios';
import { GetBREResult, HeaderApi, ApproveManualBRE, DeleteBREResult, RunBusinessRulesEngine } from '../../api';

const BRE = ({ lead_id }) => {

  const masterBreCategory = useSelector(state => state.masters?.breCategoryMaster);
  const [breRuleResult, setBreRuleResult] = useState([]);
  const [breStatus, setBreStatus] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleApproval = async (ruleId, decision) => {
    if (!remarks.trim()) {
      setError("Remarks are required!");
      return;
    }

    const confirmApproval = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to approve this rule?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: false,
    });

    if (confirmApproval) {
      handleManualApproval(ruleId, remarks);
      setRemarks(""); // Clear the remarks after saving
      setError(""); // Clear error message
    }
  };

  const fetchBreResult = async (value) => {
    setBreStatus(false);
    if (value > 0) {
      const apiUrl = `${GetBREResult}${lead_id}`;
      try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.data.status === 1) {
          setBreRuleResult(response.data.data);
          setBreStatus(response.data.data.length > 0);
        }
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    fetchBreResult(lead_id);
  }, [lead_id]);

  const toggleCategory = (id) => {
    setActiveCategory((prev) => (prev === id ? null : id));
  };

  const openModal = (rule) => {
    setSelectedRule(rule);
  };

  const closeModal = () => {
    setSelectedRule(null);
  };

  const handleManualApproval = async (id, remarks) => {
    const apiUrl = `${ApproveManualBRE}`;
    try {
      const response = await axios.post(apiUrl, { id: parseInt(id, 10), remarks: remarks }, { headers: HeaderApi });
      if (response.data.status === 1) {
        fetchBreResult(lead_id);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        setBreStatus(true);
      }

    } catch (error) {
      console.error('Error fetching state list:', error);
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
    closeModal();
  };

  const handleExecuteRule = async (e) => {
    e.preventDefault();
    const confirmApproval = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to run bre rule?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: false,
    });

    try {
      if (confirmApproval) {
        const response = await axios.post(RunBusinessRulesEngine, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          fetchBreResult(lead_id);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          });
          setBreStatus(true);
        }
      }

    } catch (error) {
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const handleClearExecuteRule = async (e) => {
    e.preventDefault();
    const confirmApproval = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to clear bre rule?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: false,
    });

    try {
      if (confirmApproval) {
        const response = await axios.post(DeleteBREResult, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          setBreStatus(false);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          });
        }
      }

    } catch (error) {
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const parsedInputs = (inputs) => {
    try {
      const sanitizedInputs = inputs
        .replace(/(\w+):/g, '"$1":')
        .replace(/,\s*}/g, '}');

      return JSON.parse(sanitizedInputs);
    } catch (e) {
      console.error("Failed to parse JSON:", e);
      return {};
    }
  };

  const formatTextToTitleCase = (text) => {
    if (typeof text !== 'string') {
      console.error('formatTextToTitleCase expects a string. Received:', typeof text);
      return text;
    }

    text = text.replaceAll(/_/g, ' ');

    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Card>
      <CardBody>
        {breStatus ? (
          <div style={styles.container}>
            {masterBreCategory.map((category, value) => (
              <div key={category.ID} style={styles.categoryCard}>
                <div style={styles.categoryHeader} onClick={() => toggleCategory(category.ID)}>
                  <h5 style={styles.categoryTitle}>{category.name}</h5>
                  {activeCategory === category.ID ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
                {activeCategory === category.ID && (
                  <div style={{ ...styles.categoryContent, fontSize: '11px' }}>
                    <table style={styles.table} className="table table-bordered table-hover table-striped">
                      <thead>
                        <tr>
                          <th style={styles.tableHeader}>RULE NAME</th>
                          <th style={styles.tableHeader}>REFERENCE BENCHMARK</th>
                          <th style={styles.tableHeader}>OBSERVED VALUE</th>
                          <th style={styles.tableHeader}>DATA INPUTS</th>
                          <th style={styles.tableHeader}>AUTOMATED DESCISION</th>
                          <th style={styles.tableHeader}>USER DESCISION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {breRuleResult
                          .filter((rule) => rule.rule?.category_id === category.ID)
                          .map((rule) => (
                            <tr key={rule.ID} style={styles.tableRow}>
                              <td style={styles.tableCell}><strong>
                                <span
                                  title={rule.rule?.description}
                                  style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {rule.rule?.name}
                                </span>
                              </strong>
                              </td>
                              <td style={styles.tableCell}>{formatTextToTitleCase(rule.cutoff_value)}</td>
                              <td style={styles.tableCell}>
                                {Object.entries(parsedInputs(rule.actual_value) || {}).map(
                                  ([key, value], index, array) => (
                                    <React.Fragment key={key}>
                                      <p style={styles.detailText}>
                                        {formatTextToTitleCase(key)}: {value || '--'}
                                        <br />
                                      </p>
                                      {index < array.length - 1 && <hr style={{ margin: '2px 0', borderColor: 'black', borderStyle: 'dashed' }} />}
                                    </React.Fragment>
                                  )
                                )}
                              </td>
                              <td style={styles.tableCell}>
                                {Object.entries(parsedInputs(rule.relevant_inputs) || {}).map(
                                  ([key, value], index, array) => (
                                    <React.Fragment key={key}>
                                      <p style={styles.detailText}>
                                        {formatTextToTitleCase(key)}: {value || '--'}
                                        <br />
                                      </p>
                                      {index < array.length - 1 && <hr style={{ margin: '2px 0', borderColor: 'black', borderStyle: 'dashed' }} />}
                                    </React.Fragment>
                                  )
                                )}


                              </td>
                              <td style={{ ...styles.tableCell }} >
                                {(() => {
                                  if (rule.system_decision_id === 0) {
                                    return (
                                      <span>
                                        N/A
                                      </span>
                                    );
                                  } else if (rule.system_decision_id === 1) {
                                    return (
                                      <span>
                                        APPROVED <FaCheckCircle color="green" />
                                      </span>
                                    );
                                  } else if (rule.manual_decision_id === 2) {
                                    return (
                                      <span>
                                        REFERED <FaQuestionCircle color="orange" />
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span>
                                        REJECTED <FaTimesCircle color="red" />
                                      </span>
                                    );
                                  }
                                })()}
                              </td>
                              <td style={{ ...styles.tableCell }} >
                                {
                                  rule.manual_decision_id === 1 ? (
                                    <span>
                                      {rule.manual_decision_remarks ?
                                        <span
                                          title={rule.manual_decision_remarks}
                                          style={{
                                            cursor: "pointer",
                                            position: "relative",
                                            display: "inline-flex",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                          }}
                                        >
                                          APPROVED
                                          <FaCheckCircle color="green" />
                                          <FaInfoCircle
                                            color="rgb(23, 162, 184)"
                                            style={{
                                              transition: "none",
                                            }}
                                            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(2)")}
                                            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                          />
                                        </span>
                                        :
                                        <>
                                          APPROVED < FaCheckCircle color="green" />
                                        </>
                                      }
                                    </span>
                                  ) : rule.manual_decision_id === 0 ? (
                                    <span>
                                      N/A
                                    </span>
                                  ) : rule.manual_decision_id === 3 ? (
                                    <span>
                                      REJECTED <FaTimesCircle color="red" />
                                    </span>
                                  ) : (
                                    <button
                                      style={styles.actionButton}
                                      onClick={() => openModal(rule)}
                                    >
                                      DECISSION
                                    </button>
                                  )
                                }
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}

            {/* Modal */}
            {selectedRule && (
              <div style={styles.modalOverlay}>
                <div style={styles.modalContent}>
                  <h3>Manual Approval: {selectedRule.rule?.name}</h3>
                  <p>
                    <strong>Cut Off Value:</strong> {selectedRule.cutoff_value}
                  </p>
                  <p>
                    <strong>Actual Value:</strong>{" "}
                    {Object.entries(parsedInputs(selectedRule.actual_value) || {}).map(
                      ([key, value]) => (
                        <span key={key}>
                          {key}: {value} <br />
                        </span>
                      )
                    )}
                  </p>
                  <textarea
                    placeholder="Enter remarks"
                    style={{
                      ...styles.textarea,
                      borderColor: error ? "red" : "#ddd",
                    }}
                    value={remarks.toUpperCase()}
                    onChange={handleRemarksChange}
                  ></textarea>
                  {error && (
                    <p style={{ color: "red", fontSize: "0.9rem", marginTop: "0.5rem" }}>
                      {error}
                    </p>
                  )}
                  <div style={styles.modalActions}>
                    <button
                      style={styles.saveButton}
                      onClick={() => handleApproval(selectedRule?.ID, remarks)}
                    >
                      APPROVE
                    </button>
                    <button style={styles.rejectButton} onClick={closeModal}>
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <CardFooter className="text-center" style={{ marginBottom: '0px' }}>
          {breStatus ? (
            <Button color="btn btn-outline-warning" onClick={handleClearExecuteRule}>CLEAR RULE CHECK</Button>
          ) :
            <Button color="btn btn-outline-success" onClick={handleExecuteRule}>EXECUTE RULE CHECK</Button>
          }
        </CardFooter>
      </CardBody>
    </Card >
  );
};

const styles = {
  categoryCard: {
    marginBottom: "7px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  categoryHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
    background: "linear-gradient(90deg, rgb(0 12 146 / 78%), rgba(0, 116, 189, 0.9))",
    color: "#fff",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  categoryTitle: {
    margin: 0
  },
  categoryContent: {
    background: "rgb(188 211 242 / 52%)",
    padding: "1rem",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid #ddd",
    // tableLayout: 'fixed',
  },
  tableHeader: {
    padding: "0.75rem",
    backgroundColor: "rgb(247 127 0 / 50%)",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    textAlign: "center",
    verticalAlign: "middle",
  },
  tableRow: {
    borderBottom: "1px solid #ddd",
  },
  tableCell: {
    padding: "0.75rem",
    textAlign: 'center',
    border: "1px solid #ddd",
    fontSize: "11px",
    verticalAlign: "middle",
  },
  detailText: {
    margin: 0,
    fontSize: "11px",
    color: "#333",
  },
  actionButton: {
    background: "#0074bd",
    color: "#fff",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    cursor: "pointer",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    background: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    width: "400px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  },
  modalActions: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-around",
  },
  saveButton: {
    background: "#28a745",
    color: "#fff",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    cursor: "pointer",
  },
  rejectButton: {
    background: "#dc3545",
    color: "#fff",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    cursor: "pointer",
  },
  textarea: {
    width: "100%",
    height: "80px",
    marginTop: "1rem",
    padding: "0.5rem",
    border: "1px solid #ddd",
    borderRadius: "4px",
  }
};

export default BRE;
