import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Form, FormGroup, Input, Label, Row, Col, CardFooter, Button, FormFeedback } from 'reactstrap';
import axios from 'axios';
import {
  SanctionLead, ApplicationRecommend, ApplicationSendBack, LeadReject,
  UpdateApplicationDetails, GetCityListByStateId, GetPincodeListByCityId,
  LeadRecommend, HeaderApi, GetEmploymentDetails,
  DisbursalSendBack, DisbursalRecommend, DisbursalPending
} from '../../api';
import { formatDateYYYYMMDD, formatDate } from '../../utils';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import { fetchPersonalData } from '../Task/Tasks';
import { useSelector, useDispatch } from 'react-redux';
import { setCustomerDetails } from '../../Redux/Slices/customerDetailsSlice';


const Update = "UPDATE DETAILS";
const Edit = "EDIT DETAILS";
const cellStyle = {
  width: '25%',
  whiteSpace: 'nowrap', // Prevent wrapping
  overflow: 'hidden',  // Hide overflow
  textOverflow: 'ellipsis', // Add ellipsis for overflowing text
};

const Application = ({ lead_id }) => {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState({});
  const [leaddata, setLeadData] = useState({});
  const stateList = useSelector(state => state.masters.stateMaster);
  const [aadhaarCityList, setAadhaarCityList] = useState([]);
  const [employmentCityList, setEmploymentCityList] = useState([]);
  const [aadhaarState, setAadhaarState] = useState({});
  const [aadhaarCity, setAadhaarCity] = useState({});
  const [cityList, setCityList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  const [employmentData, setEmploymentData] = useState({});
  const [empState, setEmpState] = useState({});
  const [empCity, setEmpCity] = useState({});
  const [employerType, setEmployerType] = useState({});
  const [salaryMode, setSalaryMode] = useState({});
  const salaryModeList = useSelector(state => state.masters.salaryModeMaster);
  const [industry, setIndustry] = useState({});
  const [empOccupation, setEmpOccupation] = useState({});
  const industryList = useSelector(state => state.masters.industryMaster);
  const empOccupationList = useSelector(state => state.masters.occupationMaster);
  const employerTypeList = useSelector(state => state.masters.employerTypeMaster);
  const [workMode, setWorkMode] = useState({});
  const workModeList = useSelector(state => state.masters.workModeMaster);
  const [aadhaarPincodeList, setAadhaarPincodeList] = useState([]);
  const [employmentPincodeList, setEmploymentPincodeList] = useState([]);
  const designationList = useSelector(state => state.masters.designationMaster);
  const [designation, setDesignation] = useState([]);
  const [department, setDepartment] = useState([]);
  const departmentList = useSelector(state => state.masters.departmentMaster);
  const religionList = useSelector(state => state.masters.religionMaster);
  const residenceTypeList = useSelector(state => state.masters.residenceMaster);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [rejectionFormData, setrejectionFormData] = useState({});
  const [leadReject, setLeadReject] = useState(false);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [uniqueSubTypes, setUniqueSubTypes] = useState([]);
  const rejectionMasterList = useSelector(state => state.masters.rejectionReasonsMaster);
  const userID = localStorage.getItem('user_id');
  const [rejectBtnVisible, setRejectBtnVisible] = useState(false);
  const roleIds = useSelector(state => state.auth.roles);
  const customerDetails = useSelector(state => state.customerDetails);
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const convertFormData = (data) => ({
    ...data,
    lead_id: parseInt(lead_id, 10),
    alternate_mobile: parseInt(data.alternate_mobile, 10),
    alternate_email: data.official_email,
    mobile: parseInt(data.mobile, 10),
    gender: parseInt(data.gender, 10),
    pincode: parseInt(data.pincode, 10),
    religion_id: parseInt(data.religion, 10),
    residence_state_id: parseInt(data.residence_state, 10),
    residence_city_id: parseInt(data.residence_city, 10),
    complete_aadhar_no: data.aadhar_no,
    aadhar_no: data.aadhar_no.slice(-4),
    aadhaar_state_id: parseInt(data.aadhaar_state, 10),
    aadhaar_city_id: parseInt(data.aadhaar_city, 10),
    aadhaar_pincode: parseInt(data.aadhaar_pincode, 10),
    emp_state_id: parseInt(data.emp_state, 10),
    emp_city_id: parseInt(data.emp_city, 10),
    emp_pincode: parseInt(data.emp_pincode, 10),
    emp_salary_mode_id: parseInt(data.salary_mode, 10),
    emp_work_mode_id: parseInt(data.work_mode, 10),
    emp_occupation_id: parseInt(data.occupation, 10),
    emp_industry_id: parseInt(data.industry, 10),
    emp_designation_id: parseInt(data.emp_designation, 10),
    emp_department_id: parseInt(data.emp_department, 10),
    emp_employer_type_id: parseInt(data.emp_employer, 10),
    emp_residence_since: formatDateYYYYMMDD(data.employed_since),
    residence_type_id: parseInt(data.residence_type, 10),
  });

  const fetchEmploymentData = useCallback(async () => {
    let apiUrl = `${GetEmploymentDetails}${lead_id}`;
    try {
      const response = await axios.get(apiUrl, { headers: HeaderApi });
      setEmploymentData(response.data.data);
      setEmpState(response.data.data.emp_state);
      setEmpCity(response.data.data.emp_city);
      setEmployerType(response.data.data.emp_employer_type);
      setSalaryMode(response.data.data.emp_salary_mode);
      setIndustry(response.data.data.emp_industry);
      setEmpOccupation(response.data.data.emp_occupation);
      setWorkMode(response.data.data.emp_work_mode);
      setDesignation(response.data.data.emp_designation);
      setDepartment(response.data.data.emp_department);

      localStorage.setItem('customer_designation', response.data.data.emp_designation.name);
    } catch (error) {
      console.error('Error with the Axios request:', error);
      setData({});
    }
  }, [lead_id]);

  useEffect(async () => {
    try {

      // const personalData = await fetchPersonalData(lead_id);
      const personalData = customerDetails;
      setData(personalData.data || {});
      setLeadData(personalData.data.lead || {});
      setAadhaarState(personalData.data.aadhaar_state || {});
      setAadhaarCity(personalData.data.aadhaar_city || {});
    } catch (error) {
      console.error('Error fetching or parsing personal_data from localStorage:', error);
    }

  }, [lead_id, editMode]);

  useEffect(() => {
    fetchEmploymentData();
    fetchAadhaarCityList(data.aadhaar_state_id);
    fetchEmploymentCityList(employmentData.emp_state_id);
    fetchAadhaarPincodeList(data.aadhaar_city_id);
    fetchEmploymentPincodeList(employmentData.emp_city_id);
    fetchCityList(leaddata.state_id);
    fetchPincodeList(leaddata.city_id);
  }, [lead_id]);

  useEffect(() => {


    setFormData({
      first_name: leaddata.first_name ? leaddata.first_name : "",
      middle_name: leaddata.middle_name ? leaddata.middle_name : "",
      surname: leaddata.surname ? leaddata.surname : "",
      mobile: leaddata.mobile ? leaddata.mobile : "",
      alternate_mobile: leaddata.alternate_mobile ? leaddata.alternate_mobile : "",
      email: leaddata.email ? leaddata.email : "",
      official_email: leaddata.alternate_email ? leaddata.alternate_email : "",
      dob: data.dob != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(data.dob) : "",
      father_name: data?.father_name || "",
      mother_name: data?.mother_name || "",
      gender: data.gender ? data.gender : "",
      residence_address1: data.residence_address1 ? data.residence_address1 : "",
      residence_address2: data.residence_address2 ? data.residence_address2 : "",
      residence_address3: data.residence_address3 ? data.residence_address3 : "",
      residence_since: data.residence_since != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(data.residence_since) : "",
      residence_type: data.residence_type_id ? data.residence_type_id : "",
      residence_landmark: data.residence_landmark ? data.residence_landmark : "",
      residence_state: data.residence_state_id ? data.residence_state_id : "",
      residence_city: data.residence_city_id ? data.residence_city_id : "",
      pincode: data.residence_pincode ? data.residence_pincode : "",
      religion: data.religion_id ? data.religion_id : "",
      aadhar_no: data.aadhar_no ? data.aadhar_no : "",
      complete_aadhar_no: data.complete_aadhar_no ? data.complete_aadhar_no : "",
      aadhaar_address1: data.aadhaar_address1 ? data.aadhaar_address1 : "",
      aadhaar_address2: data.aadhaar_address2 ? data.aadhaar_address2 : "",
      aadhaar_address3: data.aadhaar_address3 ? data.aadhaar_address3 : "",
      aadhaar_landmark: data.aadhaar_landmark ? data.aadhaar_landmark : "",
      aadhaar_state: data.aadhaar_state_id ? data.aadhaar_state_id : "",
      aadhaar_city: data.aadhaar_city_id ? data.aadhaar_city_id : "",
      aadhaar_pincode: data.aadhaar_pincode ? data.aadhaar_pincode : "",
      emp_name: employmentData.emp_name ? employmentData.emp_name : "",
      emp_address1: employmentData.emp_address1 ? employmentData.emp_address1 : "",
      emp_address2: employmentData.emp_address2 ? employmentData.emp_address2 : "",
      emp_address3: employmentData.emp_address3 ? employmentData.emp_address3 : "",
      emp_address_landmark: employmentData?.emp_address_landmark || "",
      emp_state: employmentData.emp_state_id ? employmentData.emp_state_id : "",
      emp_city: employmentData.emp_city_id ? employmentData.emp_city_id : "",
      emp_pincode: employmentData.emp_pincode ? employmentData.emp_pincode : "",
      emp_website: employmentData.emp_website ? employmentData.emp_website : "",
      emp_employer: employerType.ID ? employerType.ID : "",
      salary_mode: salaryMode.ID ? salaryMode.ID : "",
      work_mode: workMode.ID ? workMode.ID : "",
      occupation: empOccupation.ID ? empOccupation.ID : "",
      industry: industry.ID ? industry.ID : "",
      emp_department: employmentData?.emp_department_id || "",
      emp_designation: employmentData?.emp_designation_id || "",
      employed_since: employmentData.emp_residence_since != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(employmentData.emp_residence_since) : "",
    });
  }, [data, employmentData, leaddata, workMode, salaryMode, empOccupation, industry]);

  const fetchCityList = async (value) => {
    if (value > 0) {
      const apiUrl = `${GetCityListByStateId}`;
      try {
        const response = await axios.post(apiUrl, { state_id: parseInt(value, 10) }, { headers: HeaderApi });
        setCityList(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const fetchPincodeList = async (value) => {
    if (value > 0) {
      const apiUrl = `${GetPincodeListByCityId}`;
      try {
        const response = await axios.post(apiUrl, { city_id: parseInt(value, 10) }, { headers: HeaderApi });
        setPincodeList(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const fetchEmploymentCityList = async (value) => {
    if (value > 0) {
      const apiUrl = `${GetCityListByStateId}`;
      try {
        const response = await axios.post(apiUrl, { state_id: parseInt(value, 10) }, { headers: HeaderApi });
        setEmploymentCityList(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const fetchAadhaarCityList = async (value) => {
    if (value > 0) {
      const apiUrl = `${GetCityListByStateId}`;
      try {
        const response = await axios.post(apiUrl, { state_id: parseInt(value, 10) }, { headers: HeaderApi });
        setAadhaarCityList(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const fetchEmploymentPincodeList = async (value) => {
    if (value > 0) {
      const apiUrl = `${GetPincodeListByCityId}`;
      try {
        const response = await axios.post(apiUrl, { city_id: parseInt(value, 10) }, { headers: HeaderApi });
        setEmploymentPincodeList(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const fetchAadhaarPincodeList = async (value) => {
    if (value > 0) {
      const apiUrl = `${GetPincodeListByCityId}`;
      try {
        const response = await axios.post(apiUrl, { city_id: parseInt(value, 10) }, { headers: HeaderApi });
        setAadhaarPincodeList(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const onEditSubmit = async (e) => {
    e.preventDefault();
    // const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
    const skipValidationKeys = ['aadhar_no'];
    const errors = Object.keys(formData).reduce((acc, key) => {
      if (!skipValidationKeys.includes(key)) {
        return { ...acc, [key]: validateField(key, formData[key]) };
      }
      return acc;
    }, {});
    setFormErrors(errors);
    if (!Object.values(errors).some(error => error)) {
      var convertedData = convertFormData(formData);
      let apiUrl = `${UpdateApplicationDetails}${lead_id}`;
      try {
        const response = await axios.put(apiUrl, convertedData, { headers: HeaderApi });
        toast.success("Application Details Updated Successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        try {
          const responseData = await fetchPersonalData(lead_id);
          dispatch(setCustomerDetails(responseData));
          localStorage.setItem('personal_data', JSON.stringify(responseData));
          localStorage.setItem('application_status', responseData.lead.application_status);
          setData(responseData);
        } catch (error) {
          console.error('Error fetching or parsing personal_data:', error);
        }
        fetchEmploymentData();
        navigate(`/lead-details/${lead_id}/10`);
      } catch (error) {
        toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
      setEditMode(false);
    }
  };

  const validateField = (name, value) => {
    const valueString = value.toString().trim();
    switch (name) {
      case 'first_name': return valueString.trim() ? '' : 'Name is required';
      case 'father_name': return valueString.trim() ? '' : 'Father Name is required';
      case 'aadhar_no': return valueString.length == 12 ? '' : 'Aadhaar 12 digit is required';
      case 'gender': return valueString.trim() ? '' : 'Gender is required';
      case 'religion': return valueString.trim() ? '' : 'Religion is required';
      case 'dob': return valueString.trim() ? '' : 'DOB is required';
      case 'email': return valueString.trim() ? (/\S+@\S+\.\S+/.test(valueString) ? '' : 'Email is invalid') : 'Email is required';
      case 'mobile': return valueString.trim() ? (/^\d{10}$/.test(valueString) ? '' : 'Mobile must be 10 digits') : 'Mobile is required';
      case 'residence_address1': return valueString.trim() ? '' : 'Address Line 1 is required';
      case 'residence_state': return parseInt(valueString, 10) > 0 ? '' : 'State is required';
      case 'residence_city': return parseInt(valueString, 10) > 0 ? '' : 'City is required';
      case 'pincode': return parseInt(valueString, 10) > 0 ? '' : 'Pincode is required';
      case 'aadhaar_state': return parseInt(valueString, 10) > 0 ? '' : 'State is required';
      case 'aadhaar_city': return parseInt(valueString, 10) > 0 ? '' : 'City is required';
      case 'aadhaar_pincode': return parseInt(valueString, 10) > 0 ? '' : 'Pincode is required';
      case 'emp_state': return parseInt(valueString, 10) > 0 ? '' : 'State is required';
      case 'emp_city': return parseInt(valueString, 10) > 0 ? '' : 'City is required';
      case 'emp_pincode': return parseInt(valueString, 10) > 0 ? '' : 'Pincode is required';
      case 'aadhaar_address1': return valueString.trim() ? '' : 'Address Line 1 is required';
      case 'aadhaar_landmark': return valueString.trim() ? '' : 'Landmark is required';
      case 'emp_employer': return valueString.trim() ? '' : 'Company name is required';
      case 'emp_address1': return valueString.trim() ? '' : 'Address Line 1 is required';
      case 'emp_address_landmark': return valueString.trim() ? '' : 'Landmark is required';
      case 'salary_mode': return valueString.trim() ? '' : 'Salary Mode is required';
      case 'work_mode': return valueString.trim() ? '' : 'Work Mode is required';
      case 'emp_department': return valueString.trim() ? '' : 'Department is required';
      case 'emp_designation': return valueString.trim() ? '' : 'Designation is required';
      case 'employed_since': return valueString.trim() ? '' : 'Employed Since is required';
      case 'residence_since': return valueString.trim() ? '' : 'Residence Since is required';
      case 'residence_type': return valueString.trim() ? '' : 'Residence Type is required';
      default: return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.target.value = value.toUpperCase();

    if (name === 'residence_state' && value > 0) {
      fetchCityList(value);
    }

    if (name === 'aadhaar_state' && value > 0) {
      fetchAadhaarCityList(value);
    }

    if (name === 'emp_state' && value > 0) {
      fetchEmploymentCityList(value);
    }

    if (name === 'residence_city' && value > 0) {
      fetchPincodeList(value);
    }

    if (name === 'aadhaar_city' && value > 0) {
      fetchAadhaarPincodeList(value);
    }

    if (name === 'emp_city' && value > 0) {
      fetchEmploymentPincodeList(value);
    }

    setFormData((formData) => ({
      ...formData,
      [name]: value.toUpperCase()
    }));

    setFormErrors((errors) => ({
      ...errors,
      [name]: validateField(name, value)
    }));
  };

  const skipValidationKeys = ['complete_aadhar_no'];
  const renderInput = useCallback((key, value) => {
    if (skipValidationKeys.includes(key)) {
      return false;
    }

    switch (key) {
      case "employed_since":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            defaultValue={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "residence_since":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            defaultValue={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "residence_since":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            defaultValue={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "aadhaar_state":
        return (
          <Input id="aadhaar_state" name="aadhaar_state" type="select" defaultValue={formData.aadhaar_state} invalid={!!formErrors['aadhaar_state']} onChange={handleInputChange} >
            <option value="">Select State</option>
            {stateList.map((state, index) => (<option key={index} value={state.ID}>{state.name}</option>))}
          </Input>
        );
      case "emp_state":
        return (
          <Input id="emp_state" name="emp_state" type="select" defaultValue={formData.emp_state} invalid={!!formErrors['emp_state']} onChange={handleInputChange} >
            <option value="">Select State</option>
            {stateList.map((state, index) => (<option key={index} value={state.ID}>{state.name}</option>))}
          </Input>
        );
      case "aadhaar_city":
        return (
          <Input id="aadhaar_city" name="aadhaar_city" type="select" defaultValue={formData.aadhaar_city} invalid={!!formErrors['aadhaar_city']} onChange={handleInputChange} >
            <option value="">Select City</option>
            {aadhaarCityList.map((city, index) => (<option key={index} value={city.ID}>{city.name}</option>))}
          </Input>
        );
      case "emp_city":
        return (
          <Input id="emp_city" name="emp_city" type="select" defaultValue={formData.emp_city} invalid={!!formErrors['emp_city']} onChange={handleInputChange} >
            <option value="">Select City</option>
            {employmentCityList.map((city, index) => (<option key={index} value={city.ID}>{city.name}</option>))}
          </Input>
        );
      case "aadhaar_pincode":
        return (
          <Input id="aadhaar_pincode" name="aadhaar_pincode" type="select" defaultValue={formData.aadhaar_pincode} invalid={!!formErrors['aadhaar_pincode']} onChange={handleInputChange} >
            <option value="">Select Pincode</option>
            {aadhaarPincodeList.map((pincode, index) => (<option key={index} value={pincode.pincode}>{pincode.value}</option>))}
          </Input>
        );
      case "emp_pincode":
        return (
          <Input id="emp_pincode" name="emp_pincode" type="select" defaultValue={formData.emp_pincode} invalid={!!formErrors['emp_pincode']} onChange={handleInputChange} >
            <option value="">Select Pincode</option>
            {employmentPincodeList.map((pincode, index) => (<option key={index} value={pincode.pincode}>{pincode.value}</option>))}
          </Input>
        );
      case "dob":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            defaultValue={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "gender":
        return (
          <Input id="gender" name="gender" type="select" defaultValue={formData.gender} invalid={!!formErrors['gender']} onChange={handleInputChange} >
            <option value="">Select Gender</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
            <option value="3">Other</option>
          </Input>
        );
      case "residence_state":
        return (
          <Input id="residence_state" name="residence_state" type="select" defaultValue={formData.residence_state} invalid={!!formErrors['residence_state']} onChange={handleInputChange} >
            <option value="">Select State</option>
            {stateList.map((state, index) => (<option key={index} value={state.ID}>{state.name}</option>))}
          </Input>
        );
      case "residence_city":
        return (
          <Input id="residence_city" name="residence_city" type="select" defaultValue={formData.residence_city} invalid={!!formErrors['residence_city']} onChange={handleInputChange} >
            <option value="">Select City</option>
            {cityList.map((city, index) => (<option key={index} value={city.ID}>{city.name}</option>))}
          </Input>
        );
      case "pincode":
        return (
          <Input id="pincode" name="pincode" type="select" defaultValue={formData.pincode} invalid={!!formErrors['pincode']} onChange={handleInputChange} >
            <option value="">Select Pincode</option>
            {pincodeList.map((pincode, index) => (<option key={index} value={pincode.pincode}>{pincode.value}</option>))}
          </Input>
        );
      case "religion":
        return (
          <Input id="religion" name="religion" type="select" defaultValue={formData.religion} invalid={!!formErrors['religion']} onChange={handleInputChange} >
            <option value="">Select Religion</option>
            {religionList.map((religion, index) => (<option key={index} value={religion.ID}>{religion.name}</option>))}
          </Input>
        );
      case "salary_mode":
        return (
          <Input id="salary_mode" name="salary_mode" type="select" defaultValue={formData.salary_mode} invalid={!!formErrors['salary_mode']} onChange={handleInputChange} >
            <option value="">Select Salary Mode</option>
            {salaryModeList.map((salary, index) => (<option key={index} value={salary.ID}>{salary.name}</option>))}
          </Input>
        );
      case "work_mode":
        return (
          <Input id="work_mode" name="work_mode" type="select" defaultValue={formData.work_mode} invalid={!!formErrors['work_mode']} onChange={handleInputChange} >
            <option value="">Select Work Mode</option>
            {workModeList && (workModeList.map((workMode, index) => (<option key={index} value={workMode.ID}>{workMode.name}</option>)))}
          </Input>
        );
      case "occupation":
        return (
          <Input id="occupation" name="occupation" type="select" defaultValue={formData.occupation} invalid={!!formErrors['occupation']} onChange={handleInputChange} >
            <option value="">Select Occupation</option>
            {empOccupationList.map((occupation, index) => (<option key={index} value={occupation.ID}>{occupation.name}</option>))}
          </Input>
        );
      case "industry":
        return (
          <Input id="industry" name="industry" type="select" defaultValue={formData.industry} invalid={!!formErrors['industry']} onChange={handleInputChange} >
            <option value="">Select Industry</option>
            {industryList.map((industry, index) => (<option key={index} value={industry.ID}>{industry.name}</option>))}
          </Input>
        );
      case "emp_designation":
        return (
          <Input id="emp_designation" name="emp_designation" type="select" defaultValue={formData.emp_designation} invalid={!!formErrors['emp_designation']} onChange={handleInputChange} >
            <option value="">Select Designation</option>
            {designationList.map((designation, index) => (<option key={index} value={designation.ID}>{designation.name}</option>))}
          </Input>
        );
      case "emp_department":
        return (
          <Input id="emp_department" name="emp_department" type="select" defaultValue={formData.emp_department} invalid={!!formErrors['emp_department']} onChange={handleInputChange} >
            <option value="">Select Department</option>
            {departmentList.map((department, index) => (<option key={index} value={department.ID}>{department.name}</option>))}
          </Input>
        );
      case "emp_employer":
        return (
          <Input id="emp_employer" name="emp_employer" type="select" defaultValue={formData.emp_employer} invalid={!!formErrors['emp_employer']} onChange={handleInputChange} >
            <option value="">Select Department</option>
            {employerTypeList.map((department, index) => (<option key={index} value={department.ID}>{department.name}</option>))}
          </Input>
        );
      case "residence_type":
        return (
          <Input id="residence_type" name="residence_type" type="select" defaultValue={formData.residence_type} invalid={!!formErrors['residence_type']} onChange={handleInputChange} >
            <option value="">Select Residence Type</option>
            {residenceTypeList.map((department, index) => (<option key={index} value={department.ID}>{department.name}</option>))}
          </Input>
        );
      case "aadhar_no":
        return (
          <Input
            id="aadhar_no"
            name="aadhar_no"
            defaultValue={formData.complete_aadhar_no}
            onChange={handleInputChange}
            invalid={!!formErrors["aadhar_no"]}
          />
        );
      default:
        return (
          <Input
            id={key}
            name={key}
            defaultValue={value}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
    }
  }, [formData, formErrors, handleInputChange, employmentPincodeList, religionList, stateList]);

  const renderFormGroups = Object.entries(formData).reduce((acc, [key, value], index, array) => {
    if (index % 2 === 0) {
      acc.push(array.slice(index, index + 2));
    }
    return acc;
  }, []).map((pair, index) => (
    <FormGroup row key={index}>
      {pair.map(([key, value]) => (
        !skipValidationKeys.includes(key) && (
          <Fragment key={key}>
            <Label for={key} sm={2}>
              {key.replace(/_/g, ' ').toUpperCase()}
            </Label>
            <Col sm={4}>
              {renderInput(key, value)}
              {formErrors[key] && <FormFeedback>{formErrors[key]}</FormFeedback>}
            </Col>
          </Fragment>
        )
      ))}
    </FormGroup>
  ));

  const navigate = useNavigate();
  const navigateToLeadDetails = () => {
    navigate(`${process.env.PUBLIC_URL}/dashboard`);
  };

  const leadRecommed = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to recommend this case. After recommended, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    const skipValidationKeys = ['aadhar_no'];
    const errors = Object.keys(formData).reduce((acc, key) => {
      if (!skipValidationKeys.includes(key)) {
        return { ...acc, [key]: validateField(key, formData[key]) };
      }
      return acc;
    }, {});
    setFormErrors(errors);
    if (!Object.values(errors).some(error => error)) {
      if (result.value) {
        try {
          const response = await axios.post(LeadRecommend, { lead_id: lead_id }, { headers: HeaderApi });
          toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
          navigateToLeadDetails();
        } catch (error) {
          toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 15000 });
          if (error.response?.status === 401) {
            localStorage.clear();
            window.location.reload();
          }
        }
        setEditMode(false);
      } else {
        SweetAlert.fire(
          'Action cancelled by ' + localStorage.getItem('name')
        );
      }
    } else {
      console.log(errors);
      toast.error('Please fill all the required fields.', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
    }
    setBtnDisabled(false);
  };

  const leadDataReject = async (e) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to reject this application!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(LeadReject, { lead_id: parseInt(lead_id, 10), rejection_reason_id: parseInt(rejectionFormData.rejection_id, 10), remarks: rejectionFormData.rejection_remark }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Rejected!',
            'This application has been rejected.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Rejected!',
            'This application has not been rejected.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem rejecting the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);
  };

  useEffect(() => {
    const uniqueType = rejectionMasterList.reduce((acc, current) => {
      acc[current.reason] = current.reason;
      return acc;
    }, {});

    setUniqueTypes(Object.keys(uniqueType));
  }, [data, leadReject]);

  const handleRejectionChange = (e) => {
    const selectedType = e.target.value;
    const subTypes = rejectionMasterList.filter((rejection) => rejection.reason === selectedType);
    setUniqueSubTypes(subTypes);
  };

  const handleSubRejectionChange = (e) => {
    const { name, value } = e.target;
    e.target.value = value.toUpperCase();
    setrejectionFormData((formData) => ({
      ...formData,
      [name]: value.toUpperCase()
    }));
  };

  const applicationRecommend = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to recommend this case. After recommended, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(ApplicationRecommend, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Recommended!',
            'This application has been recommended.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Recommended!',
            'This application has not been recommended.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem recommending the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);
    setBtnDisabled(false);
  };



  const leadSendBack = async (e) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to sand back this case. After sending back, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(ApplicationSendBack, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Sent Back!',
            'This application has been sent back.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Sent Back!',
            'This application has not been sent back.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem sending back the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);

  };

  const sanction = async (e) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to Sanction this case. After sanctioning, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(SanctionLead, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Sanctioned!',
            'This application has been sanctioned.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Sanctioned!',
            'This application has not been sanctioned.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem sanctioning the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);
  };

  const disbursalSendBack = async (e) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to sand back this case. After sending back, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(DisbursalSendBack, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Sent Back!',
            'This application has been sent back.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Sent Back!',
            'This application has not been sent back.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem sending back the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);

  };

  const disbursalRecommend = async (e) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to Disbursal Recommend this case. After Disbursal Recommend, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(DisbursalRecommend, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Recommended!',
            'This disbursal has been recommended.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Recommended!',
            'This disbursal has not been recommended.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem disbursal recommending the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);
  };

  const disbursalPending = async (e) => {
    e.preventDefault();
    const result = await SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to Disbursal Pending this case. After Disbursal Pending, you will not be able to revert this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
      reverseButtons: false
    });

    if (result.value) {
      try {
        const response = await axios.post(DisbursalPending, { lead_id: parseInt(lead_id, 10) }, { headers: HeaderApi });
        if (response.data.status === 1) {
          SweetAlert.fire(
            'Disbursal Pending!',
            'This disbursal has been to pending.',
            'success'
          );
        } else {
          SweetAlert.fire(
            'Disbursal Pending!',
            'This disbursal has not been to pending.',
            'error'
          );
        }
        navigateToLeadDetails();
      } catch (error) {
        SweetAlert.fire(
          'Error!',
          'There was a problem disbursal Pending the application.<br/><br/>' + '<span style="color: red">' + error.response?.data?.error + '</span>',
          'error'
        );
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    } else {
      SweetAlert.fire(
        'Action cancelled by ' + localStorage.getItem('name')
      );
    }
    setEditMode(false);
  };

  const RenderFooter = ({ isLeadRecommend, onRecommend, editMode, setEditMode, leadReject, setLeadReject }) => {
    return (
      <CardFooter className="text-center">
        {isLeadRecommend ? (
          btnDisabled ? (
            <Button color="btn btn-outline-success" style={{ marginRight: 10 }} onClick={onRecommend} disabled>
              Recommending...
            </Button>
          ) : (
            <Button color="btn btn-outline-success" style={{ marginRight: 10 }} onClick={onRecommend}>
              LEAD RECOMMEND
            </Button>
          )
        ) : (
          btnDisabled ? (
            <Button color="btn btn-outline-success" style={{ marginRight: 10 }} onClick={onRecommend} disabled>
              Recommending...
            </Button>
          ) : (
            <Button color="btn btn-outline-success" style={{ marginRight: 10 }} onClick={onRecommend}>
              APPLICATION RECOMMEND
            </Button>
          )
        )}

        {editMode ? (
          <Button color="btn btn-outline-info" onClick={() => setEditMode(false)}>UPDATE</Button>
        ) : (
          <Button color="btn btn-outline-info" onClick={() => setEditMode(true)}>EDIT</Button>
        )}

        <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={() => setLeadReject(!leadReject)}>
          {leadReject ? "CANCEL" : "REJECT"}
        </Button>
      </CardFooter>
    );
  };

  // RejectionView

  useEffect(() => {
    let stages = ["S8"];
    if (roleIds.includes(2) && stages.includes(leaddata.stage)) {
      setRejectBtnVisible(true);
    }
  }, [leaddata]);


  return (
    <Fragment>
      <div className="edit-profile" style={{ marginTop: 10 }}>
        <Row>
          <Col xl="12">
            <Card>
              <Form onSubmit={onEditSubmit}>
                <CardBody>
                  {editMode ? (
                    <>
                      {renderFormGroups.length > 0 ? renderFormGroups : null}
                    </>
                  ) : (
                    <Row>
                      <table className="table table-bordered table-hover table-striped" style={{ tableLayout: 'fixed', width: '100%' }}>
                        <tbody>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>LEAD ID</th>
                            <td style={cellStyle} title={leaddata.ID}>{leaddata.ID ? leaddata.ID : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>LOAN NO</th>
                            <td style={cellStyle} title={leaddata.loan_no}>{leaddata.loan_no ? leaddata.loan_no : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>LOAN APPLIED</th>
                            <td style={cellStyle} title={leaddata.applied_amount}>{leaddata.applied_amount ? leaddata.applied_amount : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>TENURE</th>
                            <td style={cellStyle} title={leaddata.applied_tenure}>{leaddata.applied_tenure ? leaddata.applied_tenure : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>FATHER NAME</th>
                            <td style={cellStyle} title={data.father_name}>{data.father_name ? data.father_name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>MOTHER NAME</th>
                            <td style={cellStyle} title={data.mother_name}>{data.mother_name ? data.mother_name : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>OBLIGATION</th>
                            <td style={cellStyle} title={leaddata.obligations}>{leaddata.obligations ? leaddata.obligations : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>PROMO CODE</th>
                            <td style={cellStyle} title={leaddata.promocode}>{leaddata.promocode ? leaddata.promocode : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>AADHAR NUMBER</th>
                            <td style={cellStyle} title={data.aadhar_no}>{data.aadhar_no ? "XXXXXXXX" + data.aadhar_no : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>AADHAR LINE 1</th>
                            <td style={cellStyle} title={data.aadhaar_address1}>{data.aadhaar_address1 ? data.aadhaar_address1 : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>AADHAR LINE 2</th>
                            <td style={cellStyle} title={data.aadhaar_address2}>{data.aadhaar_address2 ? data.aadhaar_address2 : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>AADHAR LINE 3</th>
                            <td style={cellStyle} title={data.aadhaar_address3}>{data.aadhaar_address3 ? data.aadhaar_address3 : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>AADHAR LANDMARK</th>
                            <td style={cellStyle} title={data.aadhaar_landmark}>{data.aadhaar_landmark ? data.aadhaar_landmark : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>AADHAR STATE</th>
                            <td style={cellStyle} title={aadhaarState.name}>{aadhaarState.name ? aadhaarState.name : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>AADHAR CITY</th>
                            <td style={cellStyle} title={aadhaarCity.name}>{aadhaarCity.name ? aadhaarCity.name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>AADHAR PINCODE</th>
                            <td style={cellStyle} title={data.aadhaar_pincode}>{data.aadhaar_pincode ? data.aadhaar_pincode : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>COMPANY NAME</th>
                            <td style={cellStyle} title={employmentData.emp_name}>{employmentData.emp_name ? employmentData.emp_name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>OFFICE LINE 1</th>
                            <td style={cellStyle} title={employmentData.emp_address1}>{employmentData.emp_address1 ? employmentData.emp_address1 : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>OFFICE LINE 2</th>
                            <td style={cellStyle} title={employmentData.emp_address2}>{employmentData.emp_address2 ? employmentData.emp_address2 : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>OFFICE LINE 3</th>
                            <td style={cellStyle} title={employmentData.emp_address3}>{employmentData.emp_address3 ? employmentData.emp_address3 : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>OFFICE LANDMARK</th>
                            <td style={cellStyle} title={employmentData.emp_address_landmark}>{employmentData.emp_address_landmark ? employmentData.emp_address_landmark : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>OFFICE STATE</th>
                            <td style={cellStyle} title={empState.name}>{empState.name ? empState.name : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>OFFICE CITY</th>
                            <td style={cellStyle} title={empCity.name}>{empCity.name ? empCity.name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>OFFICE PINCODE</th>
                            <td style={cellStyle} title={employmentData.emp_pincode}>{employmentData.emp_pincode ? employmentData.emp_pincode : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>SALARY MODE</th>
                            <td style={cellStyle} title={salaryMode.name}>{salaryMode.name ? salaryMode.name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>WORK MODE</th>
                            <td style={cellStyle} title={workMode.name}>{workMode.name ? workMode.name : "-"}</td>
                          </tr>

                          <tr>
                            <th className="text-nowrap" style={cellStyle}>WEBSITE</th>
                            <td style={cellStyle} title={employmentData.emp_website}>{employmentData.emp_website ? employmentData.emp_website : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>COMPANY TYPE</th>
                            <td style={cellStyle} title={employerType.name}>{employerType.name ? employerType.name : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>OCCUPATION</th>
                            <td style={cellStyle} title={empOccupation.name}>{empOccupation.name ? empOccupation.name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>INDUSTRY</th>
                            <td style={cellStyle} title={industry.name}>{industry.name ? industry.name : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>DEPARTMENT</th>
                            <td style={cellStyle} title={department.name}>{department.name ? department.name : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>DESIGNATION</th>
                            <td style={cellStyle} title={designation.name}>{designation.name ? designation.name : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle}>EMPLOYED SINCE</th>
                            <td style={cellStyle} title={employmentData.emp_residence_since}>{employmentData.emp_residence_since ? formatDate(employmentData.emp_residence_since) : "-"}</td>
                            <th className="text-nowrap" style={cellStyle}>PRESENT SERVICE TENURE</th>
                            <td style={cellStyle} title={employmentData.emp_present_service_tenure}>{employmentData.emp_present_service_tenure ? employmentData.emp_present_service_tenure : "-"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                  )}
                </CardBody>
                {editMode && (
                  <CardFooter className="text-end">
                    <Button color="btn btn-outline-primary" type="submit">{editMode ? Update : Edit}</Button>
                    <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={() => setEditMode(!editMode)}>CANCEL</Button>
                  </CardFooter>
                )}
              </Form>
              {!editMode && (
                <>
                  {leadReject && (
                    <CardFooter className="text-center">
                      <>
                        <Col sm={3} style={{ float: 'left' }}>
                          <Input type="select" onChange={handleRejectionChange}>
                            <option value="">Select Reason</option>
                            {uniqueTypes.map((reasonRejection, index) => (<option key={index} value={reasonRejection.ID}>{reasonRejection}</option>))}
                          </Input>
                        </Col>
                        <Col sm={3} style={{ float: 'left', marginLeft: 10 }}>
                          <Input id="rejection_id" name="rejection_id" type="select" onChange={handleSubRejectionChange}>
                            <option value="">Select Sub Reason</option>
                            {uniqueSubTypes.map((headerRejection, index) => (<option key={index} value={headerRejection.ID}>{headerRejection.header}</option>))}
                          </Input>
                        </Col>
                        <Col sm={4} style={{ float: 'left', marginLeft: 10 }}>
                          <Input
                            id={"rejection_remark"}
                            type="text"
                            name={"rejection_remark"}
                            defaultValue={''}
                            onChange={handleSubRejectionChange}
                          />
                        </Col>
                        <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={(![12, 13].includes(leaddata.lead_status_id)) ? leadDataReject : ""}>REJECT</Button>
                      </>
                    </CardFooter>
                  )}
                  {(leaddata.lead_status_id < 14 && leaddata.stage !== "S7") && (
                    <>
                      {(leaddata.application_status == 2 && roleIds.includes(5)) ? (
                        <CardFooter className="text-center">
                          <Button color="btn btn-outline-warning" style={{ marginLeft: 10 }} onClick={leadSendBack}>SEND BACK</Button>
                          <Button color="btn btn-outline-success" style={{ marginLeft: 10 }} onClick={sanction}>SANCTION</Button>
                        </CardFooter>
                      ) : (
                        <>
                          {(leaddata.application_status == 1 && leaddata.stp_flag == 0 && (roleIds.includes(4) || roleIds.includes(3)) && (leaddata.screener_assign_user_id == userID || leaddata.credit_assign_user_id == userID)) && (
                            <RenderFooter
                              isLeadRecommend={leaddata.lead_status_id === 4}
                              onRecommend={leaddata.lead_status_id <= 5 ? leadRecommed : leaddata.lead_status_id <= 14 ? applicationRecommend : ""}
                              editMode={editMode}
                              setEditMode={setEditMode}
                              leadReject={leadReject}
                              setLeadReject={setLeadReject}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {(leaddata.lead_status_id <= 20 && leaddata.lead_status_id >= 16) && (
                <CardFooter className="text-center">
                  {(leaddata.lead_status_id === 20 && leaddata.stage == "S11" && roleIds.includes(8)) && (
                    <Button color="btn btn-outline-warning" style={{ marginLeft: 10 }} onClick={disbursalSendBack}>DISBURSAL SEND BACK</Button>
                  )}
                  {(leaddata.stage == "S10" && leaddata.application_status == 2 && leaddata.lead_status_id !== 19 && (roleIds.includes(1) || roleIds.includes(6)) && leaddata?.disbursal_assign_user_id == userID) && (
                    <Button color="btn btn-outline-success" style={{ marginLeft: 10 }} onClick={disbursalRecommend}>DISBURSAL RECOMMEND</Button>
                  )}
                  {(leaddata.stage == "S10" && leaddata.application_status == 2 && (roleIds.includes(1) || roleIds.includes(7) || roleIds.includes(8))) && (
                    <Button color="btn btn-outline-success" style={{ marginLeft: 10 }} onClick={disbursalPending}>DISBURSAL PENDING</Button>
                  )}
                  <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={() => setLeadReject(!leadReject)}>{leadReject ? "CANCEL" : "REJECT"}</Button>
                </CardFooter>) || null}

              {rejectBtnVisible && (
                <CardFooter className="text-center">
                  <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={() => setLeadReject(!leadReject)}>{leadReject ? "CANCEL" : "REJECT"}</Button>
                </CardFooter>
              ) || null}

            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Application;
