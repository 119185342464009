import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Row } from 'reactstrap';
import { Image } from '../../AbstractElements';
import HeaderContain from './HeaderContain';
import imgg from '../../assets/images/logo/logo.png';
import CheckContext from '../../_helper/customizer/index';

const Header = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  // function useWindowSize() {
  //   const [size, setSize] = useState([0, 0]);
  //   useLayoutEffect(() => {
  //     function updateSize() {
  //       setSize(window.innerWidth);
  //     }
  //     window.addEventListener('resize', updateSize);
  //     updateSize();
  //     return () => window.removeEventListener('resize', updateSize);
  //   }, []);
  //   return size;
  // }
  // const width = useWindowSize();
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  useEffect(() => {
    toggleSidebar(true);
  }, []);

  return (
    <Fragment>
      <Row className="header-wrapper" style={{padding: '8px 30px'}}>
        <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
          <div className="logo-wrapper" >
            <a href="#javascript"><Image attrImage={{ className: 'img-fluid', src: `${imgg}`, alt: '' }} /></a></div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar()} id="sidebar-toggle">
            <i className={toggle ? "fa fa-times" : "fa fa-bars"} aria-hidden="true" style={{ fontSize: "25px" }}></i>
          </div>
        </div>
        <div className="col">
          <HeaderContain />
        </div>
      </Row>
    </Fragment>
  );
};
export default Header;
