import React, { useState } from 'react';
import {
    Col,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Button,
    FormGroup,
    Label,
    Input,
    Form
} from 'reactstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ExportData, HeaderApi } from '../api';

const ExportDataComponent = () => {
    // Local error and result states
    const [formErrors, setFormErrors] = useState({});

    // We'll keep track of whether a predefined or custom range is selected
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    // Redux: get exportMaster array and userID
    const exportMaster = useSelector(state => state.masters.exportMaster);
    const userID = useSelector(state => state.auth.user.ID);

    // Main form data
    const [formData, setFormData] = useState({
        export_id: 0,       // 0 => "Select Export"
        user_id: userID,
        start_date: '',
        end_date: ''
    });

    // 1) Predefined date range options (includes "Custom Range")
    const dateRangeOptions = [
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'today', label: 'Today' },
        { value: 'tomorrow', label: 'Tomorrow' },
        { value: 'last7days', label: 'Last 7 Days' },
        { value: 'thisMonth', label: 'This Month' },
        { value: 'last3months', label: 'Last 3 Months' },
        // { value: 'last6months', label: 'Last 6 Months' },
        { value: 'custom', label: 'Custom Range' },
    ];

    // 2) Add a "Select Export" default option at the top of your existing array
    const exportOptions = [
        { value: 0, label: 'Select Export' },
        ...exportMaster.map(item => ({
            value: item.ID,
            label: `${item.name} - ${item.ID}`,
        }))
    ];

    // Helper: format a JS Date into 'YYYY-MM-DD' for <Input type="date"/>
    const formatDateForInput = (date) => {
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
        return adjustedDate.toISOString().split('T')[0];
    };

    // Handle the Predefined/Custom date range dropdown
    const handleDateRangeChange = (option) => {
        setSelectedDateRange(option);

        if (!option) {
            // If cleared, reset date fields
            setFormData(prevData => ({
                ...prevData,
                start_date: '',
                end_date: ''
            }));
            return;
        }

        if (option.value === 'custom') {
            // For "Custom Range", clear so user can choose their own dates
            setFormData(prevData => ({
                ...prevData,
                start_date: '',
                end_date: ''
            }));
        } else {
            // Otherwise, set them automatically
            const today = new Date();
            let start, end;

            switch (option.value) {
                case 'yesterday':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    break;
                case 'today':
                    start = today;
                    end = today;
                    break;
                case 'tomorrow':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    break;
                case 'last7days':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
                    end = today;
                    break;
                case 'thisMonth':
                    start = new Date(today.getFullYear(), today.getMonth(), 1);
                    end = today;
                    break;
                case 'last3months':
                    start = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
                    end = today;
                    break;
                case 'last6months':
                    start = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
                    end = today;
                    break;
                default:
                    start = '';
                    end = '';
            }

            setFormData(prevData => ({
                ...prevData,
                start_date: start ? formatDateForInput(start) : '',
                end_date: end ? formatDateForInput(end) : ''
            }));
        }
    };

    // Convert formData for the backend
    const convertFormData = (data) => ({
        ...data,
        export_id: parseInt(data.export_id, 10),
        user_id: parseInt(userID, 10),
    });

    // Format date/time for downloaded file name
    const formatLocalDateTime = (date) => {
        const pad = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
    };

    // Validate the form
    const validateForm = () => {
        const errors = {};

        // Must pick a real export (not 0)
        if (!formData.export_id) {
            errors.export_id = "Please select an export option.";
        }

        // Date fields must be filled in
        if (!formData.start_date) {
            errors.start_date = "Start date is required.";
        }
        if (!formData.end_date) {
            errors.end_date = "End date is required.";
        }

        // End date cannot be earlier than start date
        if (formData.start_date && formData.end_date) {
            const startDate = new Date(formData.start_date);
            const endDate = new Date(formData.end_date);
            if (endDate < startDate) {
                errors.end_date = "End date cannot be earlier than the start date.";
            }
        }

        return errors;
    };

    // Submit Handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            try {
                const convertedData = convertFormData(formData);
                const response = await axios.post(ExportData, convertedData, {
                    headers: HeaderApi,
                    responseType: 'blob' // We expect a file
                });

                // Check if the API reported "no data" (status = 0)
                if (response.data?.status === 0) {
                    toast.error(response.data.message || 'No data available for export', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000
                    });
                    return;
                }

                // Check if the response is actually JSON (error message) instead of a file
                const contentType = response.headers['content-type'];
                if (contentType && contentType.includes('application/json')) {
                    // Parse the JSON for error messages
                    const reader = new FileReader();
                    reader.onload = () => {
                        try {
                            const result = JSON.parse(reader.result);
                            if (result?.status === 0) {
                                toast.error(result.message || 'No data available for export', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 5000
                                });
                            }
                        } catch (err) {
                            console.error('JSON parse error:', err);
                        }
                    };
                    reader.readAsText(response.data);
                } else {
                    // Otherwise, it's presumably a file we can download
                    const exportItem = exportMaster.find(item => item.ID === formData.export_id);
                    const exportName = exportItem ? exportItem.name : 'ExportFile';
                    const currentDateTime = formatLocalDateTime(new Date());

                    const blob = new Blob([response.data], { type: contentType });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${exportName}_${formData.start_date}_${formData.end_date}_${currentDateTime}.xlsx`;
                    link.click();

                    toast.success("Data exported successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000
                    });
                }
            } catch (error) {
                console.error('Export error:', error);
                toast.error(error.response?.data?.error || 'Error occurred during export', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000
                });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
    };

    // Handle input changes (for custom range or user typing)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    // Handle the Export dropdown
    const handleExportChange = (selectedOption) => {
        setFormData(prevData => ({
            ...prevData,
            export_id: selectedOption ? selectedOption.value : 0
        }));
    };

    // Clear everything
    const handleClear = () => {
        setFormData({
            export_id: 0,
            user_id: userID,
            start_date: '',
            end_date: ''
        });
        setSelectedDateRange(null);
        setFormErrors({});
    };

    window.scrollTo(0, 0);
    return (
        <div className="container my-4">
            <Row className="justify-content-center">
                <Col xl="12">
                    <Card className="shadow border-0">
                        <CardHeader className="bg-primary text-white text-center">
                            <h4 className="mb-0">Export Raw Data</h4>
                        </CardHeader>

                        <Form onSubmit={handleSubmit}>
                            <CardBody>
                                {/* First row: Export selection & Date Range */}
                                <Row>
                                    {/* Export Dropdown */}
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="exportSelect" className="fw-semibold">
                                                Export Type
                                            </Label>
                                            <Select
                                                className="basic-single"
                                                // Find the matching item from exportOptions
                                                value={exportOptions.find(opt => opt.value === formData.export_id)}
                                                name="export_id"
                                                id="exportSelect"
                                                options={exportOptions}
                                                isSearchable
                                                onChange={handleExportChange}
                                            />
                                            {formErrors.export_id && (
                                                <div className="text-danger mt-1">
                                                    {formErrors.export_id}
                                                </div>
                                            )}
                                        </FormGroup>
                                    </Col>

                                    {/* Date Range Dropdown */}
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="dateRangeSelect" className="fw-semibold">
                                                Date Range
                                            </Label>
                                            <Select
                                                id="dateRangeSelect"
                                                value={selectedDateRange}
                                                onChange={handleDateRangeChange}
                                                options={dateRangeOptions}
                                                placeholder="Select a Date Range..."
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* Second row: only show Start/End Date if "Custom Range" is chosen */}
                                {selectedDateRange?.value === 'custom' && (
                                    <Row className="mt-3">
                                        {/* Start Date */}
                                        <Col md="6">
                                            <FormGroup>
                                                <Label for="start_date" className="fw-semibold">
                                                    Start Date
                                                </Label>
                                                <Input
                                                    id="start_date"
                                                    type="date"
                                                    name="start_date"
                                                    value={formData.start_date}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.start_date && (
                                                    <div className="text-danger mt-1">
                                                        {formErrors.start_date}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        {/* End Date */}
                                        <Col md="6">
                                            <FormGroup>
                                                <Label for="end_date" className="fw-semibold">
                                                    End Date
                                                </Label>
                                                <Input
                                                    id="end_date"
                                                    type="date"
                                                    name="end_date"
                                                    value={formData.end_date}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.end_date && (
                                                    <div className="text-danger mt-1">
                                                        {formErrors.end_date}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>

                            <CardFooter className="bg-white text-center d-flex justify-content-center">
                                <Button color="primary" className="me-3" type="submit">
                                    <i className="bi bi-file-earmark-arrow-down me-2"></i>
                                    Export Data
                                </Button>
                                <Button color="secondary" onClick={handleClear}>
                                    <i className="bi bi-x-circle me-2"></i>
                                    Clear
                                </Button>
                            </CardFooter>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ExportDataComponent;
